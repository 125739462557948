import { Divider, ScrollArea, Stack } from '@mantine/core';
import { useStrategyStore } from '../../../../../store/strategy-store/strategy-store';
import { primaryBase, purpleBase } from '../../../../../styles/design-tokens';
import { SideSectionInfo } from '../../../initiative-list/side-section/side-section-info';
import { ProgressBar } from '../../../initiative-list/side-section/side-section-progress';

export const FiltersProgress = () => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  if (!initiative) {
    return null;
  }

  return (
    <ScrollArea h="100%" scrollbarSize={0}>
      <Stack>
        <ProgressBar value={initiative.percent_progress} label="Completion" color={primaryBase} />
        <ProgressBar value={initiative.percent_elapsed} label="Time Elapsed" color={purpleBase} />
        <SideSectionInfo initiative={initiative} />
        <Divider />
      </Stack>
    </ScrollArea>
  );
};
