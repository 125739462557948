import { styled } from '@linaria/react';
import { Group } from '@mantine/core';
import { GraphData } from '../../api/process-client/process-client.type';
import { skyDark, skyLight, skyTight } from '../../styles/design-tokens';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  graphData: GraphData | undefined;
};

const ProcessOverviewTile = ({ title, description, value }: { title: string; description: string; value: string }) => {
  return (
    <Tile>
      <Text weight="medium">{title}</Text>
      <Text color={skyDark} size="small">
        {description}
      </Text>
      <Text size="large" weight="bold" style={{ marginTop: '16px' }}>
        {value}
      </Text>
    </Tile>
  );
};

export const ProcessOverview = ({ graphData }: Props) => {
  return (
    <Group grow>
      <ProcessOverviewTile
        title="Total Tasks"
        description="Total number of tasks"
        value={`${graphData?.task_count || 0}`}
      />
    </Group>
  );
};

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${skyLight};
  border-radius: 16px;
  padding: 16px;
  background-color: ${skyTight};
`;
