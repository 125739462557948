import { ColorMap } from './area-chart.types';

const areaChartData = [
  {
    date: '2024 Oct 1',
    Ready: 28,
    Blocked: 22,
    'To Deploy': 76,
    WIP: 18,
  },
  {
    date: '2024 Oct 7',
    Ready: 74,
    Blocked: 52,
    'To Deploy': 45,
    WIP: 34,
  },
  {
    date: '2024 Oct 14',
    Ready: 25,
    Blocked: 44,
    'To Deploy': 25,
    WIP: 31,
  },
  {
    date: '2024 Oct 21',
    Ready: 61,
    Blocked: 35,
    'To Deploy': 26,
    WIP: 77,
  },
  {
    date: '2024 Oct 28',
    Ready: 97,
    Blocked: 80,
    'To Deploy': 62,
    WIP: 65,
  },
  {
    date: '2024 Nov 5',
    Ready: 27,
    Blocked: 27,
    'To Deploy': 13,
    WIP: 51,
  },
];

const areaColors: ColorMap = {
  Ready: '#8A6EFF',
  Blocked: '#FF8E7F',
  'To Deploy': '#65D9E4',
  WIP: '#FFC87F',
};

const areaFillColors: ColorMap = {
  Ready: 'rgba(138, 110, 255, 0.2)',
  Blocked: 'rgba(255, 142, 127, 0.2)',
  'To Deploy': 'rgba(101, 217, 228, 0.2)',
  WIP: 'rgba(255, 200, 127, 0.2)',
};

export { areaChartData, areaColors, areaFillColors };
