import { Stack } from '@mantine/core';
import { useRef, useState } from 'react';
import { useStrategyStore } from '../../../../../store/strategy-store/strategy-store';
import { Metric } from '../../../strategy-v2.types';
import { ThroughputMode } from '../../initiative-performance.types';
import { MainSectionAreaChart } from './area-chart/area-chart';
import { MainSectionBarChart } from './bar-chart/bar-chart';
import { MainSectionLineChart } from './line-chart/line-chart';
import { MainSectionControls } from './main-section-controls';

export const MainSection = () => {
  const throughputMode = useStrategyStore((state) => state.throughputMode);

  const [metric, setMetric] = useState<Metric>(Metric.Tasks);

  const chartRef = useRef(null);

  return (
    <Stack h="100%" style={{ overflow: 'hidden' }}>
      <MainSectionControls metric={metric} setMetric={setMetric} chartRef={chartRef} />
      {throughputMode === ThroughputMode.InitiativeProgress && <MainSectionLineChart ref={chartRef} metric={metric} />}
      {throughputMode === ThroughputMode.ContributingEpics && <MainSectionBarChart />}
      {throughputMode === ThroughputMode.ContributingTeams && <MainSectionAreaChart />}
    </Stack>
  );
};
