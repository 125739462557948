import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { Filters as FiltersCost } from './cost-tab/filters/filters';
import { Filters as FiltersFlowByPhase } from './flow-by-phase-tab/filters/filters';
import { Filters as FiltersFocus } from './focus-tab/filters/filters';
import { PerformanceTab } from './initiative-performance.types';
import { Filters as FiltersRelatedTasks } from './related-tasks-tab/filters/filters';
import { Filters as FiltersTargets } from './targets-tab/filters/filters';
import { Filters as FiltersThroughput } from './throughput-tab/filters/filters';

export const InitiativePerformanceFilters = () => {
  const performanceTab = useStrategyStore((state) => state.performanceTab);

  switch (performanceTab) {
    case PerformanceTab.Throughput:
      return <FiltersThroughput />;
    case PerformanceTab.Focus:
      return <FiltersFocus />;
    case PerformanceTab.FlowByPhase:
      return <FiltersFlowByPhase />;
    case PerformanceTab.Targets:
      return <FiltersTargets />;
    case PerformanceTab.Cost:
      return <FiltersCost />;
    case PerformanceTab.RelatedTasks:
      return <FiltersRelatedTasks />;
    default:
      return null;
  }
};
