import { Flex } from '@mantine/core';
import { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelProps,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useStrategyStore } from '../../../../../../store/strategy-store/strategy-store';
import { Text } from '../../../../../../ui-library/typography/typography';
import { BarChartLegend } from './bar-chart-legend';
import { BarChartTooltip } from './bar-chart-tooltip';
import { useChartData } from './bar-chart.hooks';
import { barColors } from './bar-chart.mocks';

export const MainSectionBarChart = () => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const chartData = useChartData();
  const hasData = Array.isArray(chartData) && chartData.length > 0;

  // Get all bar keys
  const barKeys = Object.keys(barColors);

  // Custom label to display value on top of bars
  const renderCustomBarLabel = (props: LabelProps) => {
    const { x, y, width, value } = props;

    return (
      <text
        x={Number(x) + Number(width) / 2}
        y={Number(y) - 5}
        fill="#333"
        textAnchor="middle"
        fontSize={12}
        fontWeight="500"
      >
        {value}
      </text>
    );
  };

  if (!initiative || !hasData) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Text size="medium">No data available</Text>
      </Flex>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={chartData} margin={{ top: 20 }} barGap={2} barCategoryGap="20%">
        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#E0E0E0" />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          axisLine={{ stroke: '#E0E0E0' }}
          tickLine={{ stroke: '#E0E0E0' }}
        />
        <YAxis
          domain={[0, 100]}
          tick={{ fontSize: 12 }}
          axisLine={{ stroke: '#E0E0E0' }}
          tickLine={{ stroke: '#E0E0E0' }}
          label={{ value: '%', angle: -90, position: 'insideLeft', style: { textAnchor: 'middle' } }}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        />
        <Tooltip content={<BarChartTooltip />} />
        <Legend
          content={<BarChartLegend hoveredItem={hoveredItem} setHoveredItem={setHoveredItem} />}
          verticalAlign="bottom"
          height={50}
        />

        {/* Actual data bars */}
        {barKeys.map((key) => (
          <Bar
            key={key}
            dataKey={key}
            fill={barColors[key as keyof typeof barColors]}
            radius={[4, 4, 0, 0]}
            opacity={hoveredItem === null || hoveredItem === key ? 1 : 0.3}
            label={renderCustomBarLabel}
          >
            {chartData.map((index) => {
              return <Cell key={`cell-${key}-${index}`} fill={barColors[key as keyof typeof barColors]} />;
            })}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
