import { LegendProps } from 'recharts';
import { barBgColors, barColors } from './bar-chart.mocks';

type Props = LegendProps & {
  hoveredItem: string | null;
  setHoveredItem: (item: string | null) => void;
};

export const BarChartLegend = ({ hoveredItem, setHoveredItem, ...props }: Props) => {
  const { payload } = props;

  if (!payload) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        fontSize: '14px',
        color: '#555',
        fontWeight: 500,
      }}
    >
      {payload.map((entry) => {
        const isHovered = hoveredItem === entry.dataKey;
        const dataKey = entry.dataKey;
        const color = barColors[dataKey as keyof typeof barColors];
        const bgColor = barBgColors[dataKey as keyof typeof barBgColors];

        return (
          <div
            key={`legend-item-${entry.dataKey}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '6px 12px',
              borderRadius: '16px',
              backgroundColor: isHovered ? bgColor : 'transparent',
              transition: 'background-color 0.2s',
            }}
            onMouseEnter={() => setHoveredItem(entry.dataKey as string)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '4px',
                backgroundColor: color,
                marginRight: '8px',
              }}
            />
            <span>{entry.value}</span>
          </div>
        );
      })}
    </div>
  );
};
