import * as Sentry from '@sentry/react';
import { QueryObserverResult, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deleteInitiative, updateInitiative } from '../../../api/initiative-client/initiative-client';
import { useInitiative } from '../../../api/initiative-client/initiative-client.hooks';
import {
  ExtendedInitiative,
  Initiative,
  PreSaveInitiative,
} from '../../../api/initiative-client/initiative-client.type';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { Epic } from '../../../api/tasks-client/task-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { setInitiativePerformance } from '../../../store/strategy-store/strategy-store.actions';

/**
 * Hook to fetch and manage initiative performance data
 *
 * This hook fetches the extended initiative data for the currently selected initiative
 * and updates the global state with the fetched data.
 *
 * @returns {Object} An object containing the initiative performance query and loading state
 * @returns {QueryObserverResult<ExtendedInitiative, Error>} initiativePerformanceQuery - The query result for the initiative
 * @returns {boolean} isLoading - Whether the data is currently being fetched
 */
const useInitiativePerformanceData = (): {
  initiativePerformanceQuery: QueryObserverResult<ExtendedInitiative, Error>;
  isLoading: boolean;
} => {
  const { initiativeId } = useParams();

  const portfolio = useGlobalStore((state) => state.portfolio);

  const { query, initiative } = useInitiative(portfolio?.id, initiativeId);

  useEffect(() => {
    if (initiative) {
      setInitiativePerformance(initiative);
    }
  }, [initiative]);

  useEffect(() => {
    if (query.error) {
      setInitiativePerformance(null);
      Sentry.captureException(query.error);
    }
  }, [query.error]);

  return { initiativePerformanceQuery: query, isLoading: query.isFetching };
};

/**
 * Hook to retrieve initiative data for the performance view
 *
 * This hook attempts to retrieve initiative data from two possible sources:
 * 1. First, it checks for detailed initiative performance data in the store
 * 2. If not available, it falls back to basic initiative data from the initiatives list
 *
 * The hook uses the current route parameter to identify which initiative to retrieve.
 *
 * @returns {Initiative | ExtendedInitiative | null} The initiative data or null if not found
 */
const useInitiativePerformance = (): Initiative | ExtendedInitiative | null => {
  const { initiativeId } = useParams();

  const initiatives = useStrategyStore((state) => state.initiatives);
  const initiativePerformance = useStrategyStore((state) => state.initiativePerformance);

  const initiativeFallback = initiatives.find((initiative) => initiative.id === initiativeId) || null;

  const initiative = initiativePerformance || initiativeFallback;

  return initiative;
};

/**
 * Hook to update an initiative
 *
 * This hook provides a mutation function to update an initiative's details.
 * It handles validation of required IDs and automatically invalidates related queries
 * after a successful update.
 *
 * @param {Object} params - The parameters for the hook
 * @param {string | undefined} params.portfolioId - The ID of the portfolio containing the initiative
 * @param {string | undefined} params.initiativeId - The ID of the initiative to update
 * @param {Function} [params.onSuccess] - Optional callback function to execute after successful update
 * @returns {UseMutationResult<PreSaveInitiative, Error, PreSaveInitiative>} Mutation result for updating an initiative
 */
const useEditInitiative = ({
  portfolioId,
  initiativeId,
  onSuccess,
}: {
  portfolioId: string | undefined;
  initiativeId: string | undefined;
  onSuccess?: () => void;
}): UseMutationResult<PreSaveInitiative, Error, PreSaveInitiative> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['editInitiative', initiativeId] as const,
    mutationFn: async (initiative: PreSaveInitiative) => {
      if (!portfolioId) {
        throw new Error('Cannot update initiative: Portfolio ID is required');
      }
      if (!initiativeId) {
        throw new Error('Cannot update initiative: Initiative ID is required');
      }
      return updateInitiative(portfolioId, { id: initiativeId, ...initiative });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['initiatives', portfolioId] });
      queryClient.invalidateQueries({ queryKey: ['initiative', portfolioId, initiativeId] });

      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

/**
 * Hook to delete an initiative
 *
 * This hook provides a mutation function to delete an initiative.
 * It handles validation of required IDs and automatically removes and invalidates related queries
 * after a successful deletion.
 *
 * @param {Object} params - The parameters for the hook
 * @param {string | undefined} params.portfolioId - The ID of the portfolio containing the initiative
 * @param {string | undefined} params.initiativeId - The ID of the initiative to delete
 * @param {Function} [params.onSuccess] - Optional callback function to execute after successful deletion
 * @returns {UseMutationResult<void, Error, void>} Mutation result for deleting an initiative
 */
const useDeleteInitiative = ({
  portfolioId,
  initiativeId,
  onSuccess,
}: {
  portfolioId: string | undefined;
  initiativeId: string | undefined;
  onSuccess?: () => void;
}): UseMutationResult<void, Error, void> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteInitiative', initiativeId] as const,
    mutationFn: async () => {
      if (!portfolioId) {
        throw new Error('Cannot delete initiative: Portfolio ID is required');
      }
      if (!initiativeId) {
        throw new Error('Cannot delete initiative: Initiative ID is required');
      }
      return deleteInitiative(portfolioId, initiativeId);
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['initiative', initiativeId, portfolioId] });
      queryClient.invalidateQueries({ queryKey: ['initiatives', portfolioId] });

      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

/**
 * Hook to get epics that are contributing to the current initiative
 *
 * This hook retrieves epics that have contributions to the selected initiative
 * by filtering the initiative's epics based on those that appear in the
 * contributing_epics_over_time data.
 *
 * @returns {Epic[]} Array of epics that are contributing to the initiative
 */
const useContributingEpics = (): Epic[] => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  if (!initiative) {
    return [];
  }

  const { epics, contributing_epics_over_time } = initiative;

  const contributingEpicIds = Object.keys(contributing_epics_over_time);
  const contributingEpics = epics.filter((epic) => contributingEpicIds.includes(epic.id));

  return contributingEpics;
};

/**
 * Hook to get teams that are contributing to the current initiative
 *
 * This hook retrieves teams that have contributions to the selected initiative
 * by filtering the global teams based on those that appear in the
 * contributing_teams_over_time data.
 *
 * @returns {ProjectsResponse[]} Array of teams that are contributing to the initiative
 */
const useContributingTeams = (): ProjectsResponse[] => {
  const globalTeams = useGlobalStore((state) => state.teams);
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  if (!initiative) {
    return [];
  }

  const { contributing_teams_over_time } = initiative;

  const contributingTeamIds = Object.keys(contributing_teams_over_time);
  const contributingTeams = globalTeams.filter((team) => contributingTeamIds.includes(team.id));

  return contributingTeams;
};

export {
  useContributingEpics,
  useContributingTeams,
  useDeleteInitiative,
  useEditInitiative,
  useInitiativePerformance,
  useInitiativePerformanceData,
};
