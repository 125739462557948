import { Group, TextInput } from '@mantine/core';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import {
  setActivePanel,
  setSearchTerm,
  setTreeMapMetric,
  setView,
} from '../../../../store/strategy-store/strategy-store.actions';
import { Panel, TreeMapMetric, View } from '../../../../store/strategy-store/strategy-store.type';
import { baseWhite, inkLight } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Select } from '../../../../ui-library/select/select';
import { Text } from '../../../../ui-library/typography/typography';
import { useFilteredInitiatives } from '../../strategy-v2.hooks';
import { getTreeMapMetricOptions, handleDownloadCSV } from './main-section.helpers';

export const MainSectionControls = () => {
  const teams = useGlobalStore((state) => state.teams);
  const activeView = useStrategyStore((state) => state.view);
  const searchTerm = useStrategyStore((state) => state.searchTerm);
  const treeMapMetric = useStrategyStore((state) => state.treeMapMetric);
  const { initiatives } = useFilteredInitiatives();

  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  const getButtonVariant = (view: View) => {
    return activeView === view ? 'secondary' : 'outline';
  };

  const getIconColor = (view: View) => {
    return activeView === view ? baseWhite : inkLight;
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchTerm(value);
      }, 200),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  return (
    <Group justify="space-between" miw={760}>
      <Group gap={16}>
        <Group gap={8}>
          <Button
            variant={getButtonVariant(View.Table)}
            radius="xl"
            size="sm"
            leftSection={
              <Icon
                name="format_list_bulleted"
                color={getIconColor(View.Table)}
                size={16}
                style={{ cursor: 'pointer' }}
              />
            }
            onClick={() => {
              setView(View.Table);
              setActivePanel(Panel.Filters);
            }}
          >
            Table View
          </Button>
          <Button
            variant={getButtonVariant(View.TreeMap)}
            radius="xl"
            size="sm"
            leftSection={
              <Icon name="border_all" color={getIconColor(View.TreeMap)} size={16} style={{ cursor: 'pointer' }} />
            }
            onClick={() => {
              setView(View.TreeMap);
              setActivePanel(Panel.Filters);
            }}
          >
            Tree Map View
          </Button>
        </Group>
        {activeView === View.TreeMap && (
          <Group gap={8}>
            <Text size="small" lineHeight="none">
              Metric:
            </Text>
            <Select
              value={treeMapMetric}
              options={getTreeMapMetricOptions()}
              onChange={(value) => {
                setTreeMapMetric(value as TreeMapMetric);
              }}
              style={{ width: 240 }}
            />
          </Group>
        )}
      </Group>
      <Group>
        {activeView === View.Table && (
          <TextInput
            placeholder="Search for initiatives"
            radius="xl"
            size="sm"
            value={localSearchTerm}
            leftSection={<Icon name="search" variant="filled" size={16} color={inkLight} />}
            style={{ width: 300 }}
            onChange={(event) => {
              const value = event.currentTarget.value;
              setLocalSearchTerm(value);
              debouncedSearch(value);
            }}
          />
        )}
        <Button
          variant="secondary"
          radius="xl"
          size="sm"
          leftSection={<Icon name="download" color={baseWhite} variant="filled" size={16} />}
          onClick={() => handleDownloadCSV(initiatives, teams)}
        >
          Export
        </Button>
      </Group>
    </Group>
  );
};
