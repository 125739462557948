import { ComboboxItem } from '@mantine/core';
import { ThroughputMode } from '../../initiative-performance.types';

/**
 * Returns a list of options for the throughput mode dropdown
 * Each option contains a label for display and a value from the ThroughputMode enum
 *
 * @returns {ComboboxItem[]} Array of options for the throughput mode selection
 */
const getThroughputModeOptions = (): ComboboxItem[] => {
  return [
    { label: 'Initiative Progress', value: ThroughputMode.InitiativeProgress },
    { label: 'Contributing Epics', value: ThroughputMode.ContributingEpics },
    { label: 'Contributing Teams', value: ThroughputMode.ContributingTeams },
  ];
};

export { getThroughputModeOptions };
