import { useEffect } from 'react';
import { Initiative } from '../../../../api/initiative-client/initiative-client.type';
import { EmptyFilterResult } from '../../../../components/filters/empty-filter-result';
import { Table } from '../../../../components/table/table';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { resetPage, setPage, setSelectedInitiative } from '../../../../store/strategy-store/strategy-store.actions';
import { useFilteredInitiatives } from '../../strategy-v2.hooks';
import { InitiativeRecord } from '../../strategy-v2.types';
import { customSort, getTableColumns } from './main-section.helpers';

const ITEMS_PER_PAGE = 20;

export const MainSectionTable = () => {
  const page = useStrategyStore((state) => state.page);
  const portfolio = useGlobalStore((state) => state.portfolio);
  const selectedInitiative = useStrategyStore((state) => state.selectedInitiative);

  const { initiatives, isLoading } = useFilteredInitiatives();

  useEffect(() => {
    if (portfolio) {
      resetPage();
    }
  }, [portfolio]);

  if (initiatives.length === 0 && !isLoading) {
    return <EmptyFilterResult objectName="initiatives" />;
  }

  return (
    <Table<InitiativeRecord>
      data={initiatives}
      columns={getTableColumns()}
      getRowId={(initiative: Initiative) => initiative.id}
      defaultSortedBy={'name'}
      stickyHeader
      onRowClick={setSelectedInitiative}
      pageSize={ITEMS_PER_PAGE}
      currentPage={page}
      totalItems={initiatives.length}
      onPageChange={setPage}
      customSort={customSort}
      selectedRowId={selectedInitiative?.id}
    />
  );
};
