import { Initiative, KPIOverviewData } from '../../../../api/initiative-client/initiative-client.type';
import {
  graphLightRed,
  graphPictonBlue,
  graphPistachio,
  graphSunglow,
  skyBase,
} from '../../../../styles/design-tokens';
import { InitiativeByStatusDataPoint } from './overview.types';

/**
 * Transforms initiative status data into a format suitable for visualization
 *
 * @param data - KPI overview data containing initiative status information
 * @returns Array of data points containing category, value and fill color for each status
 */
const getStatusesData = (data: KPIOverviewData | null): InitiativeByStatusDataPoint[] => {
  if (!data) {
    return [];
  }

  const {
    initiatives_by_status: { by_tasks },
  } = data;

  return [
    { category: 'Off Track', value: by_tasks.off_track.value, fill: graphLightRed },
    { category: 'At Risk', value: by_tasks.at_risk.value, fill: graphSunglow },
    { category: 'On Track', value: by_tasks.on_track.value, fill: graphPistachio },
  ];
};

/**
 * Transforms initiative status data into a format suitable for visualization, focusing on empty and unknown statuses
 *
 * @param initiatives - Array of initiatives to analyze for empty status
 * @returns Array of data points containing category, value and fill color for empty and unknown date statuses
 */
const getStatusesExtraData = (initiatives: Initiative[]): InitiativeByStatusDataPoint[] => {
  if (initiatives.length === 0) {
    return [];
  }

  const noEpicsInitiatives = initiatives.filter((initiative) => initiative.epic_count === 0);
  const noEndDateInitiatives = initiatives.filter((initiative) => initiative.no_end_date);

  return [
    {
      category: 'No Epics',
      value: noEpicsInitiatives.length,
      fill: skyBase,
    },
    {
      category: 'No End Date',
      value: noEndDateInitiatives.length,
      fill: graphPictonBlue,
    },
  ];
};

export { getStatusesData, getStatusesExtraData };
