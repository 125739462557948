import { Stack, Switch } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { useEpics } from '../../../api/process-client/process-client.hooks';
import { useGetWorkflows } from '../../../api/workflows-client/workflows.client.hooks';
import { FilterPanel } from '../../../components/filters/filter-panel';
import { FilterGroup } from '../../../components/filters/filter-panel.types';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { secondaryBase } from '../../../styles/design-tokens';
import { useProcessScope } from '../../process/process.hooks';
import { TaskType } from '../../tasks-table/tasks-table.type';

type Props = {
  displayType: 'map' | 'table';
  setDisplayType: (displayType: 'map' | 'table') => void;
};

export function ProcessV2FilterPanel({ displayType, setDisplayType }: Props) {
  const organizationId = useGlobalStore((state) => state.organization)?.id || '';
  const { taskType, epic, portfolio, team, board, workflow, useWorkflow, setScope } = useProcessScope();
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);
  const [workflowBoardOptions, setWorkflowBoardOptions] = useState<{ value: string; label: string }[]>([]);

  const { data: workflows } = useGetWorkflows(organizationId);

  // Get teams from global store
  const teams = useGlobalStore((state) => state.teams || []);

  // Fetch epics for the current scope
  const { epics = [] } = useEpics(
    {
      portfolioId: portfolio?.id || null,
      projectId: team?.id || null,
      subprojectId: board?.id || null,
    },
    {
      queryKey: ['epics', portfolio?.id, team?.id, board?.id],
      enabled: Boolean(team?.id),
      staleTime: 1000 * 60 * 5,
    },
  );

  // Update workflow board options when workflow changes
  useEffect(() => {
    if (useWorkflow && workflow) {
      // Get all teams' boards and filter by the workflow's subprojects
      const options: { value: string; label: string }[] = [];

      teams.forEach((team) => {
        if (team.subprojects) {
          team.subprojects.forEach((subproject) => {
            if (workflow.subprojects.includes(subproject.id)) {
              options.push({ value: subproject.id, label: subproject.name });
            }
          });
        }
      });

      setWorkflowBoardOptions(options);

      // If the current board is not in the workflow boards, clear it
      if (board && !workflow.subprojects.includes(board.id)) {
        setScope({ board: null });
      }
    }
  }, [useWorkflow, workflow, teams, setScope, board]);

  // Handle team selection/deselection
  const handleTeamSelected = useCallback(
    (selectedTeam: any) => {
      setScope({ team: selectedTeam, board: null, epic: null });
    },
    [setScope],
  );

  // Handle board selection/deselection
  const handleBoardSelected = useCallback(
    (selectedBoard: any) => {
      if (selectedBoard === null) {
        setScope({ board: selectedBoard, epic: null, useGroups: true });
      } else {
        setScope({ board: selectedBoard, epic: null });
      }
    },
    [setScope],
  );

  // Handle epic selection/deselection
  const handleEpicSelected = useCallback(
    (selectedEpic: any) => {
      setScope({ epic: selectedEpic });
    },
    [setScope],
  );

  // Handle task type selection/deselection
  const handleTaskTypeSelected = useCallback(
    (selectedTaskType: TaskType | null) => {
      setScope({ taskType: selectedTaskType });
    },
    [setScope],
  );

  // Handle workflow selection/deselection
  const handleWorkflowChange = useCallback(
    (value: string | null) => {
      if (value) {
        const workflow = workflows?.find((w) => w.id === value);
        setScope({ workflow, board: null });
      } else {
        // Instead of allowing null, select the first workflow if available
        const firstWorkflow = workflows && workflows.length > 0 ? workflows[0] : null;
        if (firstWorkflow) {
          setScope({ workflow: firstWorkflow, board: null });
        }
      }
    },
    [workflows, setScope],
  );

  // Initialize the filter groups
  useEffect(() => {
    const groups: FilterGroup[] = [];

    // Team filter group
    if (!useWorkflow) {
      const teamFilterGroup: FilterGroup = {
        name: 'Team',
        options: teams.map((t) => ({ value: t.id, label: t.name })),
        selectedValues: team ? [team.id] : [],
        optional: false, // Make it required
        onSelectionChange: (values) => {
          if (values.length > 0) {
            const selectedTeam = teams.find((t) => t.id === values[0]);
            if (selectedTeam) {
              handleTeamSelected({ ...selectedTeam });
            } else if (teams.length > 0) {
              // If selection is invalid but teams exist, select the first team
              handleTeamSelected({ ...teams[0] });
            }
          } else if (teams.length > 0) {
            // Don't allow deselection - reselect the current team or first team
            const teamToSelect = team || teams[0];
            handleTeamSelected({ ...teamToSelect });
          }
        },
      };
      groups.push(teamFilterGroup);
    } else {
      const workflowFilterGroup: FilterGroup = {
        name: 'Workflow',
        options: workflows?.map((w) => ({ value: w.id, label: w.name })) || [],
        selectedValues: workflow ? [workflow.id] : [],
        optional: false, // Make it required (already was, but being explicit)
        onSelectionChange: (values) => {
          if (values.length > 0) {
            handleWorkflowChange(values[0] as string);
          } else if (workflows && workflows.length > 0) {
            // Don't allow deselection - reselect the current workflow or first workflow
            const workflowToSelect = workflow?.id || workflows[0].id;
            handleWorkflowChange(workflowToSelect);
          }
        },
      };
      groups.push(workflowFilterGroup);
    }

    // Board filter group - use different boards based on whether workflow is active
    const boardFilterGroup: FilterGroup = {
      name: 'Board',
      options: useWorkflow
        ? workflowBoardOptions
        : team
          ? team.subprojects.map((b) => ({ value: b.id, label: b.name }))
          : [],
      optional: true,
      selectedValues: board ? [board.id] : [],
      onSelectionChange: (values) => {
        if (values.length > 0) {
          // Find the selected board from the appropriate source
          const boardsToSearchIn = useWorkflow
            ? workflowBoardOptions
            : teams
                .flatMap((team) => team.subprojects)
                .map((subproject) => ({ value: subproject.id, label: subproject.name }));

          const selectedBoard = boardsToSearchIn.find((b) => b.value === values[0]);

          if (selectedBoard) {
            handleBoardSelected({ id: selectedBoard.value, name: selectedBoard.label });
          } else {
            handleBoardSelected(null);
          }
        } else {
          handleBoardSelected(null);
        }
      },
    };
    groups.push(boardFilterGroup);

    // Task Type filter group
    const taskTypeFilterGroup: FilterGroup = {
      name: 'Task Type',
      options: [
        { value: TaskType.Story, label: 'Story' },
        { value: TaskType.Task, label: 'Task' },
        { value: TaskType.Bug, label: 'Bug' },
        { value: TaskType.Subtask, label: 'Subtask' },
        { value: TaskType.Spike, label: 'Spike' },
      ],
      optional: true,
      selectedValues: taskType ? [taskType] : [],
      onSelectionChange: (values) => {
        handleTaskTypeSelected(values.length > 0 ? (values[0] as TaskType) : null);
      },
    };
    groups.push(taskTypeFilterGroup);

    if (!useWorkflow) {
      const epicFilterGroup: FilterGroup = {
        name: 'Epic',
        options: epics.map((e) => ({ value: e.id, label: e.name })),
        optional: true,
        selectedValues: epic ? [epic.id] : [],
        onSelectionChange: (values) => {
          if (values.length > 0) {
            const selectedEpic = epics.find((e) => e.id === values[0]);
            if (selectedEpic) {
              handleEpicSelected({ ...selectedEpic });
            } else {
              handleEpicSelected(null);
            }
          } else {
            handleEpicSelected(null);
          }
        },
      };
      groups.push(epicFilterGroup);
    }

    setFilterGroups(groups);
  }, [
    teams,
    team,
    board,
    taskType,
    epic,
    epics,
    useWorkflow,
    workflow,
    workflows,
    workflowBoardOptions,
    handleTeamSelected,
    handleBoardSelected,
    handleTaskTypeSelected,
    handleEpicSelected,
    handleWorkflowChange,
  ]);

  const handleResetFilters = () => {
    // When resetting filters, still maintain team or workflow selection
    if (useWorkflow) {
      const firstWorkflow = workflows && workflows.length > 0 ? workflows[0] : null;
      setScope({
        taskType: null,
        epic: null,
        board: null,
        workflow: firstWorkflow,
        useWorkflow: true,
      });
    } else {
      const firstTeam = teams && teams.length > 0 ? teams[0] : null;
      setScope({
        taskType: null,
        epic: null,
        board: null,
        workflow: null,
        useWorkflow: false,
        useGroups: true,
        team: firstTeam,
      });
    }
  };

  const handleWorkflowSelected = (event: any) => {
    const isChecked = event.currentTarget.checked;

    if (isChecked) {
      // Toggle ON - First clear team, board, and epic, then set workflow
      setScope({
        team: null,
        board: null,
        epic: null,
        useWorkflow: true,
      });

      // Then select the first workflow in a separate update
      setTimeout(() => {
        const firstWorkflow = workflows && workflows.length > 0 ? workflows[0] : null;
        if (firstWorkflow) {
          setScope({ workflow: firstWorkflow });
        }
      }, 0);
    } else {
      // Toggle OFF - First clear workflow and set useWorkflow false
      setScope({
        workflow: null,
        board: null,
        epic: null,
        useWorkflow: false,
        useGroups: true,
      });

      // Then select the first team in a separate update
      setTimeout(() => {
        const firstTeam = teams && teams.length > 0 ? teams[0] : null;
        if (firstTeam) {
          setScope({ team: firstTeam });
        }
      }, 0);
    }
  };

  const headerSection = (
    <Stack>
      <Switch
        label="Use Workflows"
        checked={useWorkflow}
        onChange={handleWorkflowSelected}
        color={secondaryBase}
        disabled={!workflows || workflows.length === 0}
      />
    </Stack>
  );

  return (
    <FilterPanel
      options={[
        { label: 'Map View', value: 'map' },
        { label: 'Table View', value: 'table' },
      ]}
      selectedOption={displayType}
      onOptionChange={setDisplayType}
      filterGroups={filterGroups}
      onResetFilters={handleResetFilters}
      useSelectFilters={true}
      headerSection={headerSection}
    />
  );
}
