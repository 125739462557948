import { Group, Stack } from '@mantine/core';
import { Initiative } from '../../../../api/initiative-client/initiative-client.type';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { inkBase, inkLighter } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { getInitiativeInfo } from './side-section.helpers';

type Props = {
  initiative: Initiative;
};

export const SideSectionInfo = ({ initiative }: Props) => {
  const teams = useGlobalStore((state) => state.teams);

  return (
    <Stack gap={8}>
      {getInitiativeInfo(initiative, teams).map(({ label, value }) => (
        <Group key={label} justify="space-between">
          <Text size="tiny" color={inkLighter} style={{ maxWidth: '50%', wordBreak: 'break-word' }}>
            {label}
          </Text>
          <Text size="tiny" color={inkBase} style={{ maxWidth: '50%', wordBreak: 'break-word', textAlign: 'right' }}>
            {value}
          </Text>
        </Group>
      ))}
    </Stack>
  );
};
