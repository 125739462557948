import { Divider, ScrollArea, Stack } from '@mantine/core';
import { useStrategyStore } from '../../../../../store/strategy-store/strategy-store';
import { setThroughputMode } from '../../../../../store/strategy-store/strategy-store.actions';
import { Select } from '../../../../../ui-library/select/select';
import { ThroughputMode } from '../../initiative-performance.types';
import { FiltersEpics } from './filters-epics';
import { FiltersProgress } from './filters-progress';
import { FiltersTeams } from './filters-teams';
import { getThroughputModeOptions } from './filters.helpers';

export const Filters = () => {
  const throughputMode = useStrategyStore((state) => state.throughputMode);

  const throughputModeOptions = getThroughputModeOptions();

  return (
    <Stack gap="md" h="100%">
      <Select
        options={throughputModeOptions}
        value={throughputMode}
        onChange={(value) => setThroughputMode(value as ThroughputMode)}
      />
      <Divider />
      <ScrollArea h="100%" style={{ flex: 1 }}>
        {throughputMode === ThroughputMode.InitiativeProgress && <FiltersProgress />}
        {throughputMode === ThroughputMode.ContributingEpics && <FiltersEpics />}
        {throughputMode === ThroughputMode.ContributingTeams && <FiltersTeams />}
      </ScrollArea>
    </Stack>
  );
};
