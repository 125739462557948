import { Group, ScrollArea, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { setActivePanel } from '../../../../store/strategy-store/strategy-store.actions';
import { secondaryBase, skyDark } from '../../../../styles/design-tokens';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { TreeMapDataType } from '../main-section/main-section.types';
import { useTreeMapData } from '../main-section/tree-map/tree-map.hooks';

export const SideSectionOtherInitiatives = () => {
  const navigate = useNavigate();

  const portfolio = useGlobalStore((state) => state.portfolio);
  const initiatives = useStrategyStore((state) => state.initiatives);

  const treeMapData = useTreeMapData();

  const treeMapDataOtherInitiatives = treeMapData.find((item) => item.type === TreeMapDataType.Other);

  if (!portfolio || !treeMapDataOtherInitiatives) {
    return null;
  }

  const { otherInitiativesData = {} } = treeMapDataOtherInitiatives;
  const otherInitiativeIds = Object.keys(otherInitiativesData);

  const otherInitiatives = initiatives
    .filter((initiative) => otherInitiativeIds?.includes(initiative.id))
    .sort((a, b) => (otherInitiativesData[b.id]?.percentage ?? 0) - (otherInitiativesData[a.id]?.percentage ?? 0));

  return (
    <Stack h="100%">
      <Group justify="space-between">
        <Text weight="bold" size="medium">
          Other
        </Text>
        <Icon name="close" onClick={() => setActivePanel(null)} size={20} color={skyDark} aria-label="Close panel" />
      </Group>
      <Divider />
      <ScrollArea h="100%" scrollbarSize={0}>
        <Stack gap={24}>
          {otherInitiatives.map((initiative) => (
            <Stack gap={8} key={initiative.id}>
              <Group gap={4}>
                <Text
                  size="small"
                  lineHeight="none"
                  color={secondaryBase}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/application/strategy/${portfolio.id}/${initiative.id}`);
                  }}
                >
                  {initiative.key}
                </Text>
                <Text size="small" lineHeight="none" color={skyDark}>
                  ({(otherInitiativesData[initiative.id]?.percentage ?? 0).toFixed(2)}%)
                </Text>
              </Group>
              <Text size="small" lineHeight="none" weight="medium">
                {initiative.name}
              </Text>
            </Stack>
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};
