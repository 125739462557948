import { Flex, Progress, Stack } from '@mantine/core';
import { inkBase, inkLighter } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';

type Props = {
  value: number;
  label: string;
  color: string;
};

export const ProgressBar = ({ value, label, color }: Props) => {
  return (
    <Stack gap={4}>
      <Flex justify="space-between">
        <Text size="tiny" color={inkLighter}>
          {label}
        </Text>
        <Text size="tiny" color={inkBase}>
          {value}%
        </Text>
      </Flex>
      <Progress value={value} color={color} size={4} />
    </Stack>
  );
};
