import { Flex } from '@mantine/core';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { setPerformanceTab } from '../../../store/strategy-store/strategy-store.actions';
import { baseWhite, inkLight } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { tabs } from './initiative-performance.data';

export const InitiativePerformanceTabs = () => {
  const activeTab = useStrategyStore((state) => state.performanceTab);

  return (
    <Flex gap={8} style={{ overflowX: 'auto' }}>
      {tabs.map((tab) => (
        <Button
          key={tab.id}
          radius="xl"
          size="xs"
          variant={activeTab === tab.id ? 'secondary' : 'outline'}
          onClick={() => setPerformanceTab(tab.id)}
          leftSection={<Icon name={tab.icon} size={14} color={activeTab === tab.id ? baseWhite : inkLight} pointer />}
          noAnimation
        >
          <Text size="small">{tab.label}</Text>
        </Button>
      ))}
    </Flex>
  );
};
