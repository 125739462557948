import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageLayout } from '../../../components/page-layout/page-layout';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import {
  setActivePanel,
  setInitiativePerformance,
  setSelectedInitiative,
} from '../../../store/strategy-store/strategy-store.actions';
import { Panel } from '../../../store/strategy-store/strategy-store.type';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { InitiativeModal } from '../initiative-modal/initiative-modal';
import { InitiativeModalMode } from '../initiative-modal/initiative-modal.types';
import { InitiativePerformanceControlBar } from './initiative-performance-control-bar';
import { InitiativePerformanceFilters } from './initiative-performance-filters';
import { InitiativePerformanceHeader } from './initiative-performance-header';
import { InitiativePerformanceMainSection } from './initiative-performance-main-section';
import { InitiativePerformanceModal } from './initiative-performance-modal';
import { InitiativePerformanceOverview } from './initiative-performance-overview';
import { InitiativePerformanceTabs } from './initiative-performance-tabs';
import { useDeleteInitiative, useEditInitiative, useInitiativePerformanceData } from './initiative-performance.hooks';
import { PerformanceTab, ThroughputMode } from './initiative-performance.types';

export const InitiativePerformance = () => {
  const navigate = useNavigate();

  const portfolio = useGlobalStore((state) => state.portfolio);
  const initiative = useStrategyStore((state) => state.initiativePerformance);
  const performanceTab = useStrategyStore((state) => state.performanceTab);
  const throughputMode = useStrategyStore((state) => state.throughputMode);

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { isLoading } = useInitiativePerformanceData();

  const editInitiativeMutation = useEditInitiative({
    portfolioId: portfolio?.id,
    initiativeId: initiative?.id,
    onSuccess: () => {
      setIsEditModalOpen(false);
    },
  });

  const deleteInitiativeMutation = useDeleteInitiative({
    portfolioId: portfolio?.id,
    initiativeId: initiative?.id,
    onSuccess: () => {
      setSelectedInitiative(null);
      setActivePanel(Panel.Filters);
      setIsEditModalOpen(false);
      navigate('/application/strategy', {
        replace: true,
        state: { message: `${initiative?.name} has been successfully removed.` },
      });
    },
  });

  useEffect(() => {
    return () => {
      setInitiativePerformance(null);
    };
  }, []);

  const filtersTitle =
    performanceTab === PerformanceTab.Throughput && throughputMode === ThroughputMode.InitiativeProgress
      ? 'Details'
      : 'Filters';

  return (
    <MenuDrivenContainer header={<InitiativePerformanceHeader />}>
      <BrandedLoadingOverlay visible={isLoading} variant="colored" transitionDuration={30} />
      <PageLayout
        controlBar={
          <InitiativePerformanceControlBar openInfoModal={setIsInfoModalOpen} openEditModal={setIsEditModalOpen} />
        }
        overview={{
          title: 'KPIs Overview',
          content: <InitiativePerformanceOverview />,
        }}
        tabs={<InitiativePerformanceTabs />}
        sideFilters={{
          title: filtersTitle,
          content: <InitiativePerformanceFilters />,
        }}
        mainSection={<InitiativePerformanceMainSection />}
        sideSection={null}
      />
      <InitiativePerformanceModal opened={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)} />
      {initiative && (
        <InitiativeModal
          mode={InitiativeModalMode.Edit}
          initiative={initiative}
          opened={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={editInitiativeMutation.mutateAsync}
          isSubmitting={editInitiativeMutation.isPending}
          onDelete={deleteInitiativeMutation.mutateAsync}
        />
      )}
    </MenuDrivenContainer>
  );
};
