import { Icon } from '@iconify/react/dist/iconify.js';
import { useCallback, useContext } from 'react';
import { ProcessMapping } from '../../../api/process-client/process-client.type';
import { secondaryBase } from '../../../styles/design-tokens';
import { ProcessContext } from '../context/process.context';
import { StyledSwitch } from './view-toggle';

type Props = {
  mapping: ProcessMapping;
  useGroups: boolean;
};

/**
 * Get stage names from the mapping object
 *
 * @param {ProcessMapping} mapping - The process mapping
 * @param {boolean} useGroups - Whether groups are being used
 * @returns {string[]} - Array of stage names
 */
const getStageNames = (mapping: ProcessMapping, useGroups: boolean): string[] => {
  return useGroups ? Object.keys(mapping.bf_to_customer) : Object.keys(mapping.customer_to_bf);
};

/**
 * Determines if we're currently showing a complex view for workflows
 *
 * @param {string[]} stages - The array of active stages
 * @param {ProcessMapping} mapping - The process mapping
 * @param {boolean} useGroups - Whether groups are being used
 * @returns {boolean} - Whether we're showing a complex view
 */
const isWorkflowComplexView = (stages: string[], mapping: ProcessMapping, useGroups: boolean): boolean => {
  if (!stages.length) {
    return false;
  }

  const referenceStages = getStageNames(mapping, useGroups);
  return referenceStages.length === stages.length && referenceStages.every((stage: string) => stages.includes(stage));
};

export function WorkflowViewToggle({ mapping, useGroups }: Props) {
  const { stages, setStages } = useContext(ProcessContext);

  const toggle = useCallback(() => {
    if (isWorkflowComplexView(stages as string[], mapping, useGroups)) {
      // If in complex view, switch to simple view by clearing stages
      setStages([]);
    } else {
      // If in simple view, switch to complex view by adding all stages
      setStages(getStageNames(mapping, useGroups) as any[]);
    }
  }, [stages, setStages, mapping, useGroups]);

  const isComplex = isWorkflowComplexView(stages as string[], mapping, useGroups);

  return (
    <StyledSwitch
      checked={isComplex}
      onChange={toggle}
      thumbIcon={isComplex ? <Icon icon="ic:baseline-check" color={secondaryBase} width={16} height={16} /> : null}
    />
  );
}
