import { Initiative, InitiativeBreakdownDataPoint } from '../../../../../api/initiative-client/initiative-client.type';
import { graphChefchaouenBlue } from '../../../../../styles/design-tokens';
import { TreeMapData, TreeMapDataType } from '../main-section.types';

/**
 * Percentage threshold for initiative visibility in the tree map.
 * Initiatives with a percentage below this threshold (5%) will be grouped into the "Other" category.
 * This improves visualization clarity by preventing the tree map from becoming cluttered with tiny segments
 * that would be difficult to interact with and visually interpret.
 */
const THRESHOLD = 5;

/**
 * Calculates tree map data for visualization based on initiative breakdown data.
 *
 * This function processes initiative data to create a tree map visualization structure.
 * It separates initiatives into two categories:
 * 1. Visible initiatives - those with percentage values above the defined threshold
 * 2. "Other" initiatives - those with percentage values below the threshold
 *
 * The function:
 * - Identifies which initiatives should be displayed individually vs grouped as "Other"
 * - Creates data objects for visible initiatives with their respective sizes and metadata
 * - Aggregates smaller initiatives into a single "Other" category
 * - Sorts visible initiatives by size (largest first)
 * - Combines both sets into a single array suitable for tree map visualization
 *
 * @param {Record<string, InitiativeBreakdownDataPoint>} dataset - Initiative breakdown data keyed by initiative ID
 * @param {Initiative[]} initiatives - Array of initiative objects with complete initiative information
 * @returns {TreeMapData[]} Processed data array ready for tree map visualization
 */
const calculateTreeMapData = (
  dataset: Record<string, InitiativeBreakdownDataPoint>,
  initiatives: Initiative[],
): TreeMapData[] => {
  const datasetEntries = Object.entries(dataset);

  const visibleInitiativeIds = datasetEntries
    .filter(([_, { percentage }]) => percentage >= THRESHOLD)
    .map(([initiativeId]) => initiativeId);

  const otherInitiativeIds = datasetEntries
    .filter(([initiativeId]) => !visibleInitiativeIds.includes(initiativeId))
    .map(([initiativeId]) => initiativeId);

  const visibleInitiativesData = initiatives
    .filter((initiative) => visibleInitiativeIds.includes(initiative.id))
    .map((initiative) => ({
      type: TreeMapDataType.Initiative,
      name: initiative.id,
      size: dataset[initiative.id]?.percentage ?? 0,
      initiativeId: initiative.id,
      initiativeName: initiative.name,
      initiativeKey: dataset[initiative.id]?.key ?? null,
      fill: graphChefchaouenBlue,
    }))
    .sort((a, b) => b.size - a.size);

  const otherInitiativesData = datasetEntries
    .filter(([initiativeId, _]) => otherInitiativeIds.includes(initiativeId))
    .reduce(
      (acc, [initiativeId, { key, percentage }]) => {
        if (!acc.size) {
          acc.size = percentage;
        } else {
          acc.size += percentage;
        }
        acc.otherInitiativesData[initiativeId] = { key, percentage };
        return acc;
      },
      { size: 0, otherInitiativesData: {} as Record<string, InitiativeBreakdownDataPoint> },
    );

  const combinedData = [
    ...visibleInitiativesData,
    {
      ...otherInitiativesData,
      type: TreeMapDataType.Other,
      name: 'Other',
      initiativeKey: 'Other',
      fill: graphChefchaouenBlue,
    },
  ];

  return combinedData;
};

export { calculateTreeMapData };
