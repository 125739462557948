import { styled } from '@linaria/react';
import { Flex, Stack } from '@mantine/core';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { TreemapNode } from 'recharts/types/util/types';
import { setActivePanel, setSelectedInitiative } from '../../../../../store/strategy-store/strategy-store.actions';
import { Panel } from '../../../../../store/strategy-store/strategy-store.type';
import { baseWhite, inkLight } from '../../../../../styles/design-tokens';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Text } from '../../../../../ui-library/typography/typography';
import { useFilteredInitiatives } from '../../../strategy-v2.hooks';
import { TreeMapDataType } from '../main-section.types';
import { TreeMapContent } from './tree-map-content';
import { TreeMapTooltip } from './tree-map-tooltip';
import { useTreeMapData } from './tree-map.hooks';

export const MainSectionTreeMap = () => {
  const treeMapData = useTreeMapData();
  const { initiatives } = useFilteredInitiatives({ enableSearch: false });

  // Handle treemap cell click
  const handleTreeMapClick = (data: TreemapNode) => {
    if (data && data.type === TreeMapDataType.Other) {
      setActivePanel(Panel.OtherInitiatives);
    } else if (data && data.initiativeId) {
      // Find the initiative by ID
      const initiative = initiatives.find((item) => item.id === data.initiativeId);
      if (initiative) {
        // Set the selected initiative (which will also open the side panel)
        setSelectedInitiative(initiative);
      }
    }
  };

  if (treeMapData.length === 0) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Stack align="center">
          <Icon name="info" color={inkLight} size={40} />
          <Text size="large" weight="medium" color={inkLight}>
            Tree map data not available
          </Text>
        </Stack>
      </Flex>
    );
  }

  return (
    <StyledResponsiveContainer>
      <Treemap
        data={treeMapData}
        dataKey="size"
        aspectRatio={1}
        stroke={baseWhite}
        isAnimationActive={false}
        content={<TreeMapContent />}
        onClick={handleTreeMapClick}
      >
        <Tooltip content={<TreeMapTooltip />} />
      </Treemap>
    </StyledResponsiveContainer>
  );
};

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-wrapper {
    position: absolute !important;
  }
`;
