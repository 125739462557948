import { useMemo } from 'react';
import { useStrategyStore } from '../../../../../../store/strategy-store/strategy-store';
import { barChartData } from './bar-chart.mocks';
import { BarChartDataItem } from './bar-chart.types';

/**
 * Hook to generate chart data for the initiative performance bar chart
 *
 * @returns {BarChartDataItem[]} An array of data points containing date and metric values
 */
const useChartData = (): BarChartDataItem[] => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  const chartData = useMemo(() => {
    if (!initiative) {
      return [];
    }

    return barChartData;
  }, [initiative]);

  return chartData;
};

export { useChartData };
