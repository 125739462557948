import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { Panel } from '../../../../store/strategy-store/strategy-store.type';
import { SideSectionOtherInitiatives } from './side-section-other-initiatives';
import { SideSectionSelectedInitiative } from './side-section-selected-initiative';

export const SideSection = () => {
  const activePanel = useStrategyStore((state) => state.activePanel);

  switch (activePanel) {
    case Panel.Side:
      return <SideSectionSelectedInitiative showChangeLog={false} />;
    case Panel.OtherInitiatives:
      return <SideSectionOtherInitiatives />;
    default:
      return null;
  }
};
