import { Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { InitiativeStatus } from '../../../api/initiative-client/initiative-client.type';
import { baseWhite, secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { getSourceIcon, getStatusColor } from '../initiative-list/main-section/main-section.helpers';
import { useInitiativePerformance } from './initiative-performance.hooks';

export const InitiativePerformanceHeader = () => {
  const navigate = useNavigate();

  const initiative = useInitiativePerformance();

  return (
    <Flex align="center" gap={16}>
      <Button
        variant="secondary"
        radius="xl"
        size="sm"
        leftSection={<Icon name="arrow_back" size={18} color={baseWhite} />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>

      {initiative && (
        <Flex align="center" gap={8}>
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor: getStatusColor(initiative.status?.by_tasks?.status || InitiativeStatus.Unknown),
            }}
          />
          <Text size="large" weight="medium" color={secondaryBase}>
            {initiative.key}
          </Text>
          <img src={getSourceIcon(initiative.source)} width={24} height={24} alt={initiative.source} />
          {initiative.url && (
            <Icon
              name="arrow_outward"
              size={24}
              color={secondaryBase}
              pointer
              onClick={() => {
                if (!initiative.url) {
                  return;
                }

                window.open(initiative.url, '_blank', 'noopener, noreferrer');
              }}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};
