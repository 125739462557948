import * as Sentry from '@sentry/react';
import { QueryObserverResult, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  useInitiatives,
  useKPIOverviewData,
  useSearchInitiatives,
} from '../../api/initiative-client/initiative-client.hooks';
import { Initiative, KPIOverviewData } from '../../api/initiative-client/initiative-client.type';
import { useEpics } from '../../api/process-client/process-client.hooks';
import { Epic } from '../../api/tasks-client/task-client.type';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useStrategyStore } from '../../store/strategy-store/strategy-store';
import { setEpics, setInitiatives, setKPIOverviewData } from '../../store/strategy-store/strategy-store.actions';
import { getContributingTeams } from './initiative-list/main-section/main-section.helpers';
import { InitiativeRecord, InitiativeState } from './strategy-v2.types';

/**
 * Custom hook that fetches and manages strategy data
 *
 * This hook fetches initiatives and KPI overview data for the current portfolio,
 * updates the global store with the fetched data, and handles error cases.
 *
 * @returns {Object} Object containing query results and data
 * @returns {QueryObserverResult<Initiative[]>} initiativesQuery - Query result for initiatives
 * @returns {Initiative[]} initiatives - Array of initiatives
 * @returns {QueryObserverResult<KPIOverviewData>} kpiOverviewDataQuery - Query result for KPI overview data
 * @returns {KPIOverviewData | null} kpiOverviewData - KPI overview data or null if not available
 */
const useStrategyData = (): {
  initiativesQuery: QueryObserverResult<Initiative[]>;
  initiatives: Initiative[];
  kpiOverviewDataQuery: QueryObserverResult<KPIOverviewData>;
  kpiOverviewData: KPIOverviewData | null;
} => {
  const { initiativesQuery, initiatives } = useInitiativesQuery();
  const { kpiOverviewDataQuery, kpiOverviewData } = useKPIOverviewDataQuery();
  useEpicsQuery();

  return { initiativesQuery, initiatives, kpiOverviewDataQuery, kpiOverviewData };
};

/**
 * Custom hook that provides initiatives data and query results
 *
 * This hook fetches initiatives for the current portfolio and handles
 * success and error states, updating the global store accordingly.
 *
 * @returns {Object} Object containing query results and initiatives data
 * @returns {QueryObserverResult<Initiative[]>} initiativesQuery - Query result for initiatives
 * @returns {Initiative[]} initiatives - Array of initiatives
 */
const useInitiativesQuery = (): {
  initiativesQuery: QueryObserverResult<Initiative[]>;
  initiatives: Initiative[];
} => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const { query: initiativesQuery, initiatives = [] } = useInitiatives(portfolio?.id, {
    enabled: !!portfolio?.id,
  } as UseQueryOptions<Initiative[]>);

  useEffect(() => {
    if (initiativesQuery.isSuccess) {
      setInitiatives(initiatives);
    }
  }, [initiatives, initiativesQuery.isSuccess]);

  useEffect(() => {
    if (initiativesQuery.error) {
      setInitiatives([]);
      Sentry.captureException(initiativesQuery.error);
    }
  }, [initiativesQuery.error]);

  return { initiativesQuery, initiatives };
};

/**
 * Custom hook that provides KPI overview data and query results
 *
 * This hook fetches KPI overview data for the current portfolio and handles
 * success and error states, updating the global store accordingly.
 *
 * @returns {Object} Object containing query results and KPI overview data
 * @returns {QueryObserverResult<KPIOverviewData>} kpiOverviewDataQuery - Query result for KPI overview data
 * @returns {KPIOverviewData | null} kpiOverviewData - KPI overview data or null if not available
 */
const useKPIOverviewDataQuery = (): {
  kpiOverviewDataQuery: QueryObserverResult<KPIOverviewData>;
  kpiOverviewData: KPIOverviewData | null;
} => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const { query: kpiOverviewDataQuery, kpiOverviewData = null } = useKPIOverviewData(portfolio?.id, {
    enabled: !!portfolio?.id,
  } as UseQueryOptions<KPIOverviewData>);

  useEffect(() => {
    if (kpiOverviewDataQuery.isSuccess) {
      setKPIOverviewData(kpiOverviewData ?? null);
    }
  }, [kpiOverviewData, kpiOverviewDataQuery.isSuccess]);

  useEffect(() => {
    if (kpiOverviewDataQuery.error) {
      setKPIOverviewData(null);
      Sentry.captureException(kpiOverviewDataQuery.error);
    }
  }, [kpiOverviewDataQuery.error]);

  return { kpiOverviewDataQuery, kpiOverviewData };
};

/**
 * Custom hook that provides epics data and query results
 *
 * This hook fetches epics for the current portfolio and handles
 * success and error states, updating the global store accordingly.
 *
 * @returns {Object} Object containing query results and epics data
 * @returns {QueryObserverResult<Epic[]>} epicsQuery - Query result for epics
 * @returns {Epic[]} epics - Array of epics
 */
const useEpicsQuery = (): {
  epicsQuery: QueryObserverResult<Epic[]>;
  epics: Epic[];
} => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const { query: epicsQuery, epics = [] } = useEpics(
    { portfolioId: portfolio?.id || null, projectId: null, subprojectId: null },
    {
      enabled: !!portfolio?.id,
    } as UseQueryOptions<Epic[]>,
  );

  useEffect(() => {
    if (epicsQuery.isSuccess) {
      setEpics(epics);
    }
  }, [epics, epicsQuery.isSuccess]);

  useEffect(() => {
    if (epicsQuery.error) {
      setEpics([]);
      Sentry.captureException(epicsQuery.error);
    }
  }, [epicsQuery.error]);

  return { epicsQuery, epics };
};

/**
 * Custom hook that provides filtered initiatives based on search term and filters
 *
 * This hook filters initiatives based on the current search term, source filters,
 * and status filters from the strategy store. When a search term is provided and
 * search is enabled, it uses search results instead of global initiatives.
 *
 * @param {Object} options - Configuration options
 * @param {boolean} [options.enableSearch=true] - Whether to enable search functionality
 * @returns {Object} Object containing filtered initiatives and loading state
 * @returns {Initiative[]} initiatives - Array of filtered initiatives
 * @returns {boolean} isLoading - Whether the search is loading
 */
const useFilteredInitiatives = ({
  enableSearch = true,
}: { enableSearch?: boolean } = {}): {
  initiatives: InitiativeRecord[];
  isLoading: boolean;
} => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const teams = useGlobalStore((state) => state.teams);
  const globalInitiatives = useStrategyStore((state) => state.initiatives);
  const searchTerm = useStrategyStore((state) => state.searchTerm);
  const sourceFilters = useStrategyStore((state) => state.filters.source);
  const stateFilters = useStrategyStore((state) => state.filters.state);
  const statusFilters = useStrategyStore((state) => state.filters.status);

  const { data: searchResults = [], query } = useSearchInitiatives(portfolio?.id, searchTerm, {
    enabled: !!searchTerm && !!portfolio?.id && enableSearch,
    staleTime: 0,
  });

  const initiatives = searchTerm && enableSearch ? searchResults : globalInitiatives;

  const filteredInitiatives = initiatives.filter((initiative) => {
    const passesSourceFilter = sourceFilters.includes(initiative.source);
    const passesStateFilter =
      (stateFilters.includes(InitiativeState.NoEpics) && initiative.epic_count === 0) ||
      (stateFilters.includes(InitiativeState.NoEndDate) && initiative.no_end_date === true);
    const passesStatusFilter = statusFilters.includes(initiative.status?.by_tasks?.status ?? '');

    return passesSourceFilter && (passesStatusFilter || passesStateFilter);
  });

  const formattedInitiatives = filteredInitiatives.map((initiative) => ({
    ...initiative,
    contributing_teams_label: getContributingTeams(initiative, teams),
  }));

  return { initiatives: formattedInitiatives, isLoading: query.isLoading };
};

export { useFilteredInitiatives, useStrategyData };
