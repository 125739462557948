import { ExtendedInitiative } from '../../../api/initiative-client/initiative-client.type';

/**
 * Gets the count of completed tasks for the most recent month
 *
 * This function extracts the completed tasks count from the initiative performance data
 * by finding the most recent month in the completed_subtasks_over_time data.
 *
 * @param {ExtendedInitiative | null} initiativePerformance - The initiative performance data
 * @returns {number} The count of completed tasks for the most recent month, or 0 if no data exists
 */
const getCompletedTasksCount = (initiativePerformance: ExtendedInitiative | null): number => {
  if (!initiativePerformance) {
    return 0;
  }

  const tasks = initiativePerformance?.completed_subtasks_over_time?.tasks || {};
  const monthKeys = Object.keys(tasks).sort();
  const latestMonth = monthKeys[monthKeys.length - 1];

  return latestMonth ? tasks?.[latestMonth as keyof typeof tasks] || 0 : 0;
};

export { getCompletedTasksCount };
