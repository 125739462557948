const commonInputProps = {
  styles: {
    input: {
      borderRadius: 32,
      marginTop: 4,
    },
  },
};

export { commonInputProps };
