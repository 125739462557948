const barChartData = [
  {
    date: '2024 Oct 1',
    'EPC-123': 72,
    'EPC-124': 88,
    'EPC-125': 53,
    'EPC-126': 95,
  },
  {
    date: '2024 Oct 7',
    'EPC-123': 70,
    'EPC-124': 48,
    'EPC-125': 32,
    'EPC-126': 89,
  },
  {
    date: '2024 Oct 14',
    'EPC-123': 71,
    'EPC-124': 97,
    'EPC-125': 86,
    'EPC-126': 55,
  },
  {
    date: '2024 Oct 21',
    'EPC-123': 93,
    'EPC-124': 62,
    'EPC-125': 89,
    'EPC-126': 89,
  },
  {
    date: '2024 Oct 28',
    'EPC-123': 47,
    'EPC-124': 76,
    'EPC-125': 11,
    'EPC-126': 44,
  },
  {
    date: '2024 Nov 5',
    'EPC-123': 39,
    'EPC-124': 57,
    'EPC-125': 48,
    'EPC-126': 41,
  },
];

const barColors = {
  'EPC-123': '#8A6EFF',
  'EPC-124': '#FF8E7F',
  'EPC-125': '#65D9E4',
  'EPC-126': '#FFC87F',
};

const barBgColors = {
  'EPC-123': 'rgba(138, 110, 255, 0.1)',
  'EPC-124': 'rgba(255, 142, 127, 0.1)',
  'EPC-125': 'rgba(101, 217, 228, 0.1)',
  'EPC-126': 'rgba(255, 200, 127, 0.1)',
};

export { barBgColors, barChartData, barColors };
