import { Box } from '@mantine/core';
import { TooltipProps } from 'recharts';
import {
  baseBlack,
  baseWhite,
  borderRadiusSmall,
  boxShadowBigSoft,
  secondaryBase,
  skyDark,
} from '../../../../../styles/design-tokens';
import { Text } from '../../../../../ui-library/typography/typography';
import { TreeMapData, TreeMapDataType } from '../main-section.types';

export const TreeMapTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;

    return (
      <Box
        style={{
          backgroundColor: baseWhite,
          padding: '8px 20px',
          borderRadius: borderRadiusSmall,
          boxShadow: boxShadowBigSoft,
        }}
        maw={300}
      >
        <TooltipTitle data={data} />
        <TooltipDescription data={data} />
      </Box>
    );
  }

  return null;
};

const TooltipTitle = ({ data }: { data: TreeMapData }) => {
  const percentage = <span style={{ color: skyDark }}>({data.size.toFixed(2)}%)</span>;

  const title = data.type === TreeMapDataType.Initiative ? data.initiativeKey || data.name : 'Other';
  const titleColor = data.type === TreeMapDataType.Initiative ? secondaryBase : baseBlack;

  return (
    <Text size="small" color={titleColor}>
      {title} {percentage}
    </Text>
  );
};

const TooltipDescription = ({ data }: { data: TreeMapData }) => {
  return (
    <Text size="small" weight="medium">
      {data.type === TreeMapDataType.Initiative ? data.initiativeName : 'Combined Low-Value Initiatives'}
    </Text>
  );
};
