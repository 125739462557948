import {
  ExtendedInitiative,
  PreSaveInitiative,
  RelativeBusinessValueUnits,
} from '../../../api/initiative-client/initiative-client.type';
import { Epic } from '../../../api/tasks-client/task-client.type';
import { toLocalDate } from '../../../helpers/timezone/timezone';

/**
 * Hook that provides initial values for an initiative form
 *
 * @param initiative - Optional extended initiative data to populate the form
 * @returns Partial PreSaveInitiative object with initial values
 * If initiative is provided, returns values from the initiative
 * Otherwise, returns default empty values
 */
const useInitialValues = (initiative?: ExtendedInitiative): Partial<PreSaveInitiative> => {
  if (initiative) {
    return {
      name: initiative.name,
      description: initiative.description,
      start_date: toLocalDate(initiative.start_date).toDate(),
      end_date: toLocalDate(initiative.end_date).toDate(),
      relative_business_value: initiative.relative_business_value,
      relative_business_value_unit: initiative.relative_business_value_unit,
    };
  }

  return {
    name: '',
    description: '',
    start_date: undefined,
    end_date: undefined,
    relative_business_value: null,
    relative_business_value_unit: RelativeBusinessValueUnits.numeric,
  };
};

/**
 * Hook that provides initial epics for an initiative form
 *
 * @param initiative - Optional extended initiative data to populate the form
 * @returns Array of epics or empty array if initiative is not provided
 */
const useInitialEpics = (initiative?: ExtendedInitiative): Epic[] | [] => {
  if (initiative) {
    return initiative.epics;
  }

  return [];
};

export { useInitialEpics, useInitialValues };
