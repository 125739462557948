import {
  Initiative,
  InitiativeSource,
  InitiativeStatus,
} from '../../../../api/initiative-client/initiative-client.type';
import { Team } from '../../../../api/projects-client/projects-client.type';
import { icons } from '../../../../assets/icons/icons';
import { downloadCSV } from '../../../../helpers/csv-download';
import { toLocalDate } from '../../../../helpers/timezone/timezone';
import { TreeMapMetric } from '../../../../store/strategy-store/strategy-store.type';
import { primaryBase, skyBase, skyDark, yellowBase } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { TableColumn } from '../../../data-management/components/table';
import { iconsIntegrations } from '../../../integrations/assets';
import { InitiativeRecord } from '../../strategy-v2.types';
import { CompletionCell, InitiativeIDCell } from './main-section-cell';

/**
 * Gets the table column configuration for the initiatives table
 * @returns {TableColumn<Initiative>[]} Array of column configurations
 */
const getTableColumns = (): TableColumn<InitiativeRecord>[] => {
  return [
    {
      key: 'key',
      label: 'Initiative ID',
      width: '10%',
      sortable: true,
      render: (_, initiative) => <InitiativeIDCell initiative={initiative} />,
    },
    {
      key: 'name',
      label: 'Name',
      width: '40%',
      sortable: true,
      render: (_, initiative) => <Text size="small">{initiative.name}</Text>,
    },
    {
      key: 'percent_progress',
      label: 'Completion',
      width: '10%',
      sortable: true,
      render: (_, initiative) => <CompletionCell initiative={initiative} />,
    },
    {
      key: 'end_date',
      label: 'Target End',
      width: '10%',
      sortable: true,
      render: (_, initiative) =>
        initiative.end_date ? toLocalDate(initiative.end_date).format('MMM D, YYYY') : 'No date',
    },
    {
      key: 'costs_to_date',
      label: 'Cost to Date',
      width: '10%',
      sortable: true,
      render: (_, initiative) => <Text size="small">{`$${initiative.costs_to_date?.initiative || 0}`}</Text>,
    },
    {
      key: 'contributing_teams_label',
      label: 'Teams',
      width: '10%',
      sortable: true,
      render: (_, initiative) => <Text size="small">{initiative.contributing_teams_label}</Text>,
    },
  ];
};

/**
 * Custom sort function for initiative records
 *
 * @param {InitiativeRecord[]} data - The array of initiative records to sort
 * @param {keyof InitiativeRecord} sortBy - The field to sort by
 * @param {'asc' | 'desc'} direction - The sort direction (ascending or descending)
 * @returns {InitiativeRecord[]} A new sorted array of initiative records
 */
const customSort = (
  data: InitiativeRecord[],
  sortBy: keyof InitiativeRecord,
  direction: 'asc' | 'desc',
): InitiativeRecord[] => {
  return [...data].sort((a, b) => {
    const multiplier = direction === 'asc' ? 1 : -1;

    switch (sortBy) {
      case 'key':
        return multiplier * String(a.key || '').localeCompare(String(b.key || ''));

      case 'name':
        return multiplier * String(a.name || '').localeCompare(String(b.name || ''));

      case 'percent_progress': {
        const aProgress = a.percent_progress || 0;
        const bProgress = b.percent_progress || 0;
        return multiplier * (aProgress - bProgress);
      }

      case 'end_date': {
        const aDate = a.end_date ? new Date(a.end_date).getTime() : 0;
        const bDate = b.end_date ? new Date(b.end_date).getTime() : 0;
        return multiplier * (aDate - bDate);
      }

      case 'costs_to_date': {
        const aCost = a.costs_to_date?.initiative || 0;
        const bCost = b.costs_to_date?.initiative || 0;
        return multiplier * (aCost - bCost);
      }

      case 'contributing_teams_label':
        return multiplier * a.contributing_teams_label.localeCompare(b.contributing_teams_label);

      default:
        return 0;
    }
  });
};

/**
 * Handles downloading a CSV file for the initiatives table
 *
 * @param initiatives - The initiatives to download
 * @returns {void}
 */
const handleDownloadCSV = (initiatives: Initiative[], teams: Team[]): void => {
  try {
    const headers = ['Initiative ID', 'Name', 'Completion', 'Target End', 'Cost to Date', 'Teams'];

    const rows = initiatives.map((initiative) => [
      initiative.key || 'N/A',
      initiative.name,
      initiative.percent_progress || 0,
      initiative.end_date ? toLocalDate(initiative.end_date).format('MMM D, YYYY') : 'No date',
      initiative.costs_to_date?.initiative || 0,
      getContributingTeams(initiative, teams) || 'N/A',
    ]);

    downloadCSV([headers, ...rows], 'initiatives.csv');
  } catch (error) {
    console.error('Error downloading CSV', error);
  }
};

/**
 * Returns the appropriate color for an initiative status
 *
 * @param {InitiativeStatus} status - The status of the initiative
 * @returns {string} The color code corresponding to the status
 */
const getStatusColor = (status: InitiativeStatus): string => {
  switch (status) {
    case InitiativeStatus.OnTrack:
      return primaryBase;
    case InitiativeStatus.AtRisk:
      return yellowBase;
    case InitiativeStatus.OffTrack:
      return skyDark;
    default:
      return skyBase;
  }
};

/**
 * Returns the appropriate icon for an initiative source
 *
 * @param {InitiativeSource} source - The source of the initiative
 * @returns {string} The icon path corresponding to the source
 */
const getSourceIcon = (source: InitiativeSource): string => {
  const sourceIcons = {
    [InitiativeSource.Bloomfilter]: icons.iconBloomfilter,
    [InitiativeSource.Jira]: iconsIntegrations.iconJiraCloud,
    [InitiativeSource.JDC]: iconsIntegrations.iconJiraDataCenter,
    [InitiativeSource.ADO]: iconsIntegrations.iconADO,
    [InitiativeSource.Github]: icons.iconGithub,
  };

  return sourceIcons[source];
};

/**
 * Gets a comma-separated string of contributing team names for an initiative
 *
 * @param {Initiative} initiative - The initiative to get contributing teams for
 * @param {Team[]} teams - Array of all available teams
 * @returns {string} Comma-separated list of team names that contribute to the initiative
 */
const getContributingTeams = (initiative: Initiative, teams: Team[]): string => {
  const teamIds = Object.keys(initiative.contributing_teams_over_time || {});
  const teamNames = teamIds.map((id) => teams.find((team) => team.id === id)?.name).filter(Boolean);

  return teamNames.join(', ');
};

/**
 * Returns the available metric options for the tree map visualization
 *
 * @returns {{ label: string; value: TreeMapMetric }[]} Array of metric options with labels and values
 */
const getTreeMapMetricOptions = (): { label: string; value: TreeMapMetric }[] => {
  return [
    {
      value: TreeMapMetric.CostByInitiative,
      label: 'Cost by Initiative',
    },
    {
      value: TreeMapMetric.EffortByInitiative,
      label: 'Effort by Initiative',
    },
  ];
};

export {
  customSort,
  getContributingTeams,
  getSourceIcon,
  getStatusColor,
  getTableColumns,
  getTreeMapMetricOptions,
  handleDownloadCSV,
};
