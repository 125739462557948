import { Flex, Stack } from '@mantine/core';
import { OverviewCard } from '../../../../components/overview-card/overview-card';
import { ProgressBarCard } from '../../../../components/overview-card/progress-bar-card';
import { VerticalBarCard } from '../../../../components/overview-card/vertical-bar-card';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { skyDark } from '../../../../styles/design-tokens';
import { H3, Text } from '../../../../ui-library/typography/typography';
import { getStatusesData, getStatusesExtraData } from './overview.helpers';

export const Overview = () => {
  const initiatives = useStrategyStore((state) => state.initiatives);
  const data = useStrategyStore((state) => state.kpiOverviewData);

  return (
    <>
      <Flex direction="row" gap={24}>
        <VerticalBarCard title="Initiatives by Statuses" icon="insert_chart_outlined" data={getStatusesData(data)} />
        <VerticalBarCard
          title="Initiatives by State"
          icon="insert_chart_outlined"
          data={getStatusesExtraData(initiatives)}
        />
        <ProgressBarCard
          title="Overall Initiatives Completion"
          icon="check_circle"
          value={data?.overall_completion || 0}
        />
        <OverviewCard
          title="Portfolio Throughput"
          icon="show_chart"
          content={
            <Stack justify="center" align="center" gap={0} mt={10}>
              <H3>{data?.throughput || 0}</H3>
              <Text size="tiny" lineHeight="tight" color={skyDark}>
                Tasks Completed
              </Text>
              <Text size="tiny" lineHeight="tight" color={skyDark}>
                Last 30 Days
              </Text>
            </Stack>
          }
        />
      </Flex>
    </>
  );
};
