import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { Text } from '../../../ui-library/typography/typography';
import { StyledModal } from '../initiative-modal/initiative-modal';

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const InitiativePerformanceModal = ({ opened, onClose }: Props) => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  if (!initiative) {
    return null;
  }

  return (
    <StyledModal
      opened={opened}
      onClose={onClose}
      title={
        <Text size="large" weight="bold" style={{ maxWidth: 600 }}>
          {initiative.name}
        </Text>
      }
      size={800}
      centered
    >
      <Text style={{ whiteSpace: 'pre-wrap' }}>{initiative.description || 'No description available.'}</Text>
    </StyledModal>
  );
};
