import { Flex } from '@mantine/core';
import { ReactNode } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { skyDark } from '../../styles/design-tokens';
import { OverviewCard } from './overview-card';
import { VerticalBarCardData } from './overview-card.types';

type Props = {
  title: string;
  icon?: string;
  status?: string | ReactNode;
  data: VerticalBarCardData;
};

export const VerticalBarCard = ({ title, icon, status, data }: Props) => {
  const content = (
    <Flex direction="column" gap={8}>
      {status}
      <ResponsiveContainer width="100%" height={data.length * 30}>
        <BarChart data={data} layout="vertical" margin={{ left: 0, right: 0, top: 0, bottom: 0 }} barCategoryGap={8}>
          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey="category"
            axisLine={false}
            tickLine={false}
            width={72}
            tick={({ y, payload }) => {
              return (
                <text x={0} y={y} dy={4} textAnchor="start" fill={skyDark} fontSize={12} style={{ lineHeight: 14 }}>
                  {payload.value}
                </text>
              );
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            type="category"
            dataKey="value"
            axisLine={false}
            tickLine={false}
            tick={{ fill: skyDark, fontSize: 12 }}
            width={36}
          />
          <Bar dataKey="value" radius={[4, 4, 4, 4]} barSize={14} fillOpacity={0.8} />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );

  return <OverviewCard title={title} icon={icon} content={content} />;
};
