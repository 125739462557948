import { Checkbox, ScrollArea, Stack } from '@mantine/core';
import { ChangeEvent, useState } from 'react';
import { Team } from '../../../../../api/projects-client/projects-client.type';
import { useGlobalStore } from '../../../../../store/global-store/global-store';
import { useStrategyStore } from '../../../../../store/strategy-store/strategy-store';
import { Text } from '../../../../../ui-library/typography/typography';

export const FiltersTeams = () => {
  const globalTeams = useGlobalStore((state) => state.teams);
  const initiative = useStrategyStore((state) => state.initiativePerformance);
  const teamIds = Object.keys(initiative?.contributing_teams_over_time || {});

  const teams = globalTeams.filter((team) => teamIds.includes(team.id));

  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);

  const handleCheckboxChange = (team: Team, event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTeams((prev) => (event.target.checked ? [...prev, team] : prev.filter((t) => t.id !== team.id)));
  };

  return (
    <ScrollArea h="100%" scrollbarSize={0}>
      <Stack gap={24}>
        {teams.map((team) => (
          <Stack gap={8} key={team.id}>
            <Checkbox
              label={
                <Text size="small" style={{ lineHeight: '20px' }}>
                  {team.name}
                </Text>
              }
              checked={selectedTeams.some((t) => t.id === team.id)}
              onChange={(event) => handleCheckboxChange(team, event)}
            />
          </Stack>
        ))}
      </Stack>
    </ScrollArea>
  );
};
