import { Stack } from '@mantine/core';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { View } from '../../../../store/strategy-store/strategy-store.type';
import { MainSectionControls } from './main-section-controls';
import { MainSectionTable } from './main-section-table';
import { MainSectionTreeMap } from './tree-map/tree-map';

export const MainSection = () => {
  const activeView = useStrategyStore((state) => state.view);

  return (
    <Stack h="100%" style={{ overflow: 'hidden' }}>
      <MainSectionControls />
      {activeView === View.Table && <MainSectionTable />}
      {activeView === View.TreeMap && <MainSectionTreeMap />}
    </Stack>
  );
};
