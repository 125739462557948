import { Initiative } from '../../../../api/initiative-client/initiative-client.type';
import { Team } from '../../../../api/projects-client/projects-client.type';
import { formatInitiativeDate } from '../../strategy-v2.helpers';
import { getContributingTeams } from '../main-section/main-section.helpers';
import { ChangeLogStatus } from './side-section.types';

/**
 * Generates an array of label-value pairs containing initiative information
 *
 * @param initiative - The initiative object containing the data
 * @returns An array of objects with label and value properties representing initiative details
 *          Each object contains:
 *          - label: The display label for the field
 *          - value: The corresponding value from the initiative object
 */
const getInitiativeInfo = (
  initiative: Initiative,
  teams: Team[],
): { label: string; value: string | number | null | undefined }[] => {
  return [
    {
      label: 'Start Date',
      value: formatInitiativeDate(initiative.start_date),
    },
    { label: 'Target End', value: formatInitiativeDate(initiative.end_date) },
    { label: 'Cost to Date', value: `$${initiative.costs_to_date?.initiative || 0}` },
    { label: 'Business Value', value: initiative.relative_business_value ?? '-' },
    { label: 'Teams', value: getContributingTeams(initiative, teams) || '-' },
  ];
};

/**
 * Converts a Status enum value to its corresponding display text
 *
 * @param status - The Status enum value to convert
 * @returns The human-readable text representation of the status
 */
const getStatusText = (status: ChangeLogStatus): string => {
  const labelMapping = {
    [ChangeLogStatus.Created]: 'Created',
    [ChangeLogStatus.InProgress]: 'In-Progress',
    [ChangeLogStatus.NotActive]: 'Not Active',
    [ChangeLogStatus.NotStarted]: 'Not Started',
    [ChangeLogStatus.Completed]: 'Completed',
  };

  if (!labelMapping[status]) {
    return 'Unknown';
  }

  return labelMapping[status];
};

export { getInitiativeInfo, getStatusText };
