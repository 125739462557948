import { useMemo } from 'react';
import { useStrategyStore } from '../../../../../store/strategy-store/strategy-store';
import { TreeMapMetric } from '../../../../../store/strategy-store/strategy-store.type';
import { useFilteredInitiatives } from '../../../strategy-v2.hooks';
import { TreeMapData } from '../main-section.types';
import { calculateTreeMapData } from './tree-map.helpers';

/**
 * Custom hook that provides data for the tree map visualization
 *
 * This hook processes initiative data based on the selected metric (cost or effort)
 * and returns formatted data for rendering in a tree map visualization.
 *
 * @returns {TreeMapData[]} Array of tree map data objects with name, size, and fill color
 */
const useTreeMapData = (): TreeMapData[] => {
  const { initiatives } = useFilteredInitiatives({ enableSearch: false });
  const kpiOverviewData = useStrategyStore((state) => state.kpiOverviewData);
  const treeMapMetric = useStrategyStore((state) => state.treeMapMetric);

  const treeMapData = useMemo(() => {
    const dataset =
      treeMapMetric === TreeMapMetric.CostByInitiative
        ? (kpiOverviewData?.initiative_cost_breakdown ?? {})
        : (kpiOverviewData?.initiative_effort_breakdown ?? {});

    const data = calculateTreeMapData(dataset, initiatives);

    return data;
  }, [initiatives, treeMapMetric, kpiOverviewData]);

  return treeMapData;
};

export { useTreeMapData };
