import { styled } from '@linaria/react';
import { Stack, Timeline } from '@mantine/core';
import { format } from 'date-fns';
import { skyDark } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { mockChangeLog } from './side-section.data';
import { getStatusText } from './side-section.helpers';
import { ChangeLogEntry, ChangeLogStatus } from './side-section.types';

export const SideSectionChangelog = () => {
  return (
    <Stack gap="md">
      <Text size="small" weight="medium">
        Change Log:
      </Text>

      <StyledTimeline lineWidth={1}>
        {mockChangeLog.map((entry, index) => (
          <ChangelogLabel key={`${entry.status}-${index}`} status={entry.status} date={entry.date} />
        ))}
      </StyledTimeline>
    </Stack>
  );
};

const ChangelogLabel = ({ status, date }: ChangeLogEntry) => {
  const iconName = status === ChangeLogStatus.Created ? 'fiber_manual_record' : 'arrow_forward_ios';
  const statusLabel =
    status === ChangeLogStatus.Created ? (
      'Initiative Created'
    ) : (
      <>
        Changed to <b>{getStatusText(status)}</b>
      </>
    );

  return (
    <Timeline.Item
      bullet={<Icon name={iconName} size={12} style={{ transform: 'rotate(-90deg)' }} variant="filled" />}
      lineVariant="solid"
      pb={status === ChangeLogStatus.Created ? 0 : 24}
    >
      <Text size="tiny" lineHeight="tight">
        {statusLabel}
      </Text>
      <Text size="tiny" color={skyDark} lineHeight="tight">
        {format(date, "MMMM d 'at' h:mm a zzz")}
      </Text>
    </Timeline.Item>
  );
};

const StyledTimeline = styled(Timeline)`
  .mantine-Timeline-itemBullet {
    background-color: transparent;
    border: none;
  }

  .mantine-Timeline-item {
    border-left: 1px solid var(--sky-light);
    margin-top: 0;

    &::before {
      display: none;
    }
  }
`;
