import { InitiativeBreakdownDataPoint } from '../../../../api/initiative-client/initiative-client.type';

enum TreeMapDataType {
  Initiative = 'initiative',
  Other = 'other',
}

type TreeMapData = {
  type: TreeMapDataType;
  name: string | null;
  size: number;
  fill?: string;
  initiativeId?: string;
  initiativeName?: string;
  initiativeKey?: string | null;
  otherInitiativesData?: Record<string, InitiativeBreakdownDataPoint>;
};

export { TreeMapDataType };
export type { TreeMapData };
