import { styled } from '@linaria/react';
import { Table as MantineTable, Pagination, ScrollArea } from '@mantine/core';
import { useState } from 'react';
import { inkLight, secondaryBase, skyBase, skyDark, skyLightest } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

export type TableColumn<T extends object> = {
  key: keyof T;
  label: string;
  render?: (value: T[keyof T], row: T) => React.ReactNode;
  sortable?: boolean;
  width?: string;
};

export type TableProps<T extends object> = {
  data: T[];
  columns: TableColumn<T>[];
  onRowClick?: (row: T) => void;
  selectedRowId?: string | number;
  getRowId: (row: T) => string | number;
  defaultSortedBy?: string | null;
  stickyHeader?: boolean;
  isChildRow?: (row: T) => boolean;
  getParentId?: (row: T) => string | number;
  highlightParentRows?: boolean;
  pageSize?: number;
  currentPage?: number;
  totalItems?: number;
  onPageChange?: (page: number) => void;
  customSort?: (data: T[], sortBy: keyof T, direction: 'asc' | 'desc') => T[];
};

function SortableTableHeader({
  children,
  reversed,
  sorted,
  onSort,
  sortable = true,
  style,
}: {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  sortable?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <MantineTable.Th style={style}>
      <HeaderContent onClick={sortable ? onSort : undefined} sortable={sortable}>
        <Text weight="bold" size="regular">
          {children}
        </Text>
        {sortable && (
          <Icon
            name={sorted ? (reversed ? 'south' : 'north') : 'south'}
            color={sorted ? secondaryBase : skyDark}
            size={16}
            style={{
              cursor: 'pointer',
              opacity: sorted ? 1 : 0.5,
            }}
          />
        )}
      </HeaderContent>
    </MantineTable.Th>
  );
}

export function Table<T extends object>({
  data,
  columns,
  onRowClick,
  selectedRowId,
  getRowId,
  defaultSortedBy = null,
  stickyHeader = false,
  isChildRow = () => false,
  getParentId = () => '',
  highlightParentRows = false,
  pageSize = 20,
  currentPage = 1,
  totalItems = 0,
  onPageChange,
  customSort,
}: TableProps<T>) {
  const [sortedBy, setSortedBy] = useState<keyof T | null>(defaultSortedBy as keyof T | null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof T) => {
    const reversed = field === sortedBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortedBy(field);
  };

  const sortData = (data: T[]): T[] => {
    if (!sortedBy) {
      return data;
    }

    if (customSort) {
      return customSort(data, sortedBy, reverseSortDirection ? 'desc' : 'asc');
    }

    // Group parent rows with their children
    const parentRows = data.filter((row) => !isChildRow(row));
    const childrenByParent = data.reduce(
      (acc, row) => {
        if (isChildRow(row)) {
          const parentId = getParentId(row);
          if (!acc[parentId]) {
            acc[parentId] = [];
          }
          acc[parentId].push(row);
        }
        return acc;
      },
      {} as Record<string | number, T[]>,
    );

    // Sort parent rows
    const sortedParents = [...parentRows].sort((a, b) => {
      const aValue = a[sortedBy];
      const bValue = b[sortedBy];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return reverseSortDirection ? -comparison : comparison;
      }

      const comparison = aValue < bValue ? -1 : 1;
      return reverseSortDirection ? -comparison : comparison;
    });

    // Reconstruct the array with children following their parents
    return sortedParents.flatMap((parent) => {
      const children = childrenByParent[getRowId(parent)] || [];
      return [parent, ...children];
    });
  };

  const sortedData = sortData(data);

  // Apply pagination to the sorted data if pagination props are provided
  const paginatedData = pageSize ? sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize) : sortedData;

  return (
    <StyledTable stickyHeader={stickyHeader}>
      <ScrollArea h="calc(100% - 100px)" mih="calc(100% - 100px)" scrollbarSize={0}>
        <MantineTable>
          <MantineTable.Thead
            style={{ position: stickyHeader ? 'sticky' : 'static', top: 0, backgroundColor: 'white', zIndex: 1 }}
          >
            <MantineTable.Tr>
              {columns.map((column) => (
                <SortableTableHeader
                  key={column.key.toString()}
                  sorted={sortedBy === column.key}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting(column.key)}
                  sortable={column.sortable !== false}
                  style={{ width: column.width }}
                >
                  {column.label}
                </SortableTableHeader>
              ))}
            </MantineTable.Tr>
          </MantineTable.Thead>
          <MantineTable.Tbody>
            {paginatedData.map((row) => (
              <TableRow
                key={getRowId(row)}
                onClick={() => onRowClick?.(row)}
                data-selected={selectedRowId === getRowId(row)}
                data-child={isChildRow(row)}
                data-is-project={highlightParentRows && !isChildRow(row)}
                style={{ cursor: onRowClick ? 'pointer' : 'default' }}
              >
                {columns.map((column) => (
                  <MantineTable.Td key={column.key.toString()} style={{ width: column.width }}>
                    {column.render ? column.render(row[column.key], row) : <Text>{row[column.key]?.toString()}</Text>}
                  </MantineTable.Td>
                ))}
              </TableRow>
            ))}
          </MantineTable.Tbody>
        </MantineTable>
      </ScrollArea>
      <PaginationContainer>
        <Text size="tiny" color={skyBase}>
          Showing {(currentPage - 1) * pageSize + 1} to {Math.min(currentPage * pageSize, totalItems)} of {totalItems}{' '}
          tasks
        </Text>
        {totalItems > pageSize && (
          <Pagination
            total={Math.ceil(totalItems / pageSize)}
            value={currentPage}
            onChange={onPageChange}
            size="sm"
            siblings={1}
            boundaries={3}
          />
        )}
      </PaginationContainer>
    </StyledTable>
  );
}

const HeaderContent = styled.div<{ sortable?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 8px 0px;
  margin: 0;
  text-align: left;
  border-radius: 8px;
  cursor: ${(props) => (props.sortable ? 'pointer' : 'default')};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.sortable ? skyLightest : 'transparent')};
  }
`;

const StyledTable = styled.div<{ stickyHeader?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;

  .mantine-Table-table thead tr th {
    transition: background-color 0.2s ease;
    background-color: transparent;
    padding: 8px 16px;
  }
`;

const TableRow = styled(MantineTable.Tr)`
  height: 48px;
  transition: background-color 0.2s ease;

  td {
    transition: background-color 0.2s ease;
    background-color: transparent;
    padding: 8px 16px;
  }

  &:hover td {
    background-color: ${skyLightest};
  }

  &:hover td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:hover td:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &[data-selected='true'] td {
    background-color: ${skyLightest};
  }

  &[data-selected='true'] td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &[data-selected='true'] td:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &[data-selected='true']:hover td {
    background-color: ${skyLightest};
  }

  &[data-is-project='true'] td {
    background-color: ${skyLightest};
  }

  &[data-is-project='true']:hover td {
    background-color: ${skyLightest};
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-top: 16px;
  gap: 8px;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;

  .mantine-Pagination-root {
    .mantine-Pagination-control {
      border-radius: 32px;
      padding: 6px 12px;
      margin: 0 2px;
      cursor: pointer;

      &[data-active] {
        background-color: ${secondaryBase};
        border-color: ${secondaryBase};
        color: white;
      }

      &:not([data-active]) {
        color: ${inkLight};
      }

      &:not([data-active]):hover {
        background-color: ${skyLightest};
      }
    }
  }
`;
