import { Icon } from '@iconify/react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useCommentsSidebar } from './comments-sidebar.context';

export const CommentsSidebarButton = () => {
  const { toggleCommentsSidebar, isCommentsSidebarVisible } = useCommentsSidebar();

  return (
    <Tooltip label={isCommentsSidebarVisible ? 'Close Comments' : 'Open Comments'} position="bottom">
      <ActionIcon
        onClick={toggleCommentsSidebar}
        variant="transparent"
        size="lg"
        color="gray"
        aria-label="Toggle Comments Sidebar"
      >
        <Icon icon="material-symbols:comment-outline" width={24} height={24} />
      </ActionIcon>
    </Tooltip>
  );
};
