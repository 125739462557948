import { Flex } from '@mantine/core';
import { useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Dot,
  DotProps,
  Legend,
  LegendProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { useStrategyStore } from '../../../../../../store/strategy-store/strategy-store';
import { Text } from '../../../../../../ui-library/typography/typography';
import { areaChartData, areaColors, areaFillColors } from './area-chart.mocks';
import { AreaDataKey } from './area-chart.types';

export const MainSectionAreaChart = () => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  // Sample data for the area chart - replace with actual data from your store
  const chartData = useMemo(() => areaChartData, []);

  // Get all area keys
  const areaKeys = Object.keys(areaColors) as AreaDataKey[];

  // Custom dot renderer to ensure dots appear on all data points
  const renderDot = (props: DotProps & { dataKey?: string }) => {
    const { cx, cy, dataKey } = props;
    const key = dataKey as AreaDataKey;
    const isHovered = hoveredItem === key || hoveredItem === null;

    return (
      <Dot cx={cx} cy={cy} r={4} stroke="white" strokeWidth={2} fill={areaColors[key]} opacity={isHovered ? 1 : 0.4} />
    );
  };

  // Custom tooltip
  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '8px 12px',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          }}
        >
          <p style={{ margin: '0 0 5px', fontWeight: 'bold' }}>{label}</p>
          {payload.map((entry, index) => {
            const dataKey = entry.dataKey as AreaDataKey;
            return (
              <p key={`item-${index}`} style={{ margin: '0', color: areaColors[dataKey] }}>
                {entry.name}: {entry.value}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  // Custom legend
  const CustomLegend = (props: LegendProps) => {
    const { payload = [] } = props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
          fontSize: '14px',
          color: '#555',
          fontWeight: 500,
        }}
      >
        {payload.map((entry) => {
          // Determine if this item is being hovered
          const isHovered = hoveredItem === entry.dataKey;
          const dataKey = entry.dataKey as AreaDataKey;
          const color = areaColors[dataKey];
          const bgColor = areaFillColors[dataKey];

          return (
            <div
              key={`legend-item-${entry.dataKey}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '6px 12px',
                borderRadius: '16px',
                backgroundColor: isHovered ? bgColor : 'transparent',
                transition: 'background-color 0.2s',
              }}
              onMouseEnter={() => setHoveredItem(entry.dataKey as AreaDataKey)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '4px',
                  backgroundColor: color,
                  marginRight: '8px',
                }}
              />
              <span>{entry.value}</span>
            </div>
          );
        })}
      </div>
    );
  };

  if (!initiative) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Text size="medium">No data available</Text>
      </Flex>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#E0E0E0" />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          axisLine={{ stroke: '#E0E0E0' }}
          tickLine={{ stroke: '#E0E0E0' }}
          padding={{ left: 40, right: 40 }}
        />
        <YAxis
          domain={[0, 100]}
          tick={{ fontSize: 12 }}
          axisLine={{ stroke: '#E0E0E0' }}
          tickLine={{ stroke: '#E0E0E0' }}
          label={{ value: '%', angle: -90, position: 'insideLeft', style: { textAnchor: 'middle' } }}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} verticalAlign="bottom" height={50} />

        {/* Render areas in reverse order to ensure proper stacking appearance */}
        {[...areaKeys].reverse().map((key) => (
          <Area
            key={key}
            type="monotone"
            dataKey={key}
            stackId="1"
            stroke={areaColors[key]}
            fill={areaFillColors[key]}
            strokeWidth={1.5}
            activeDot={renderDot}
            dot={renderDot}
            opacity={hoveredItem === null || hoveredItem === key ? 1 : 0.5}
            isAnimationActive={false}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
