import { Group, ScrollArea, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { InitiativeSource } from '../../../../api/initiative-client/initiative-client.type';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { setActivePanel, setSelectedInitiative } from '../../../../store/strategy-store/strategy-store.actions';
import { baseWhite, primaryBase, purpleBase, secondaryBase, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { getSourceIcon } from '../main-section/main-section.helpers';
import { SideSectionChangelog } from './side-section-changelog';
import { SideSectionInfo } from './side-section-info';
import { ProgressBar } from './side-section-progress';

type Props = {
  showChangeLog: boolean;
};

export const SideSectionSelectedInitiative = ({ showChangeLog }: Props) => {
  const navigate = useNavigate();

  const initiative = useStrategyStore((state) => state.selectedInitiative);
  const portfolio = useGlobalStore((state) => state.portfolio);

  if (!initiative || !portfolio) {
    return null;
  }

  const handleKeyClick = () => {
    if (initiative.source !== InitiativeSource.Bloomfilter && initiative.url) {
      window.open(initiative.url, '_blank', 'noopener, noreferrer');
    }
  };

  return (
    <Stack h="100%">
      <Group justify={initiative.key ? 'space-between' : 'end'}>
        {initiative.key && (
          <Group
            gap={4}
            style={{
              cursor: initiative.source !== InitiativeSource.Bloomfilter && initiative.url ? 'pointer' : 'default',
            }}
            onClick={handleKeyClick}
          >
            <img src={getSourceIcon(initiative.source)} width={16} height={16} alt={initiative.source} />
            <Text weight="bold" size="medium" color={secondaryBase}>
              {initiative.key}
            </Text>
            {initiative.source !== InitiativeSource.Bloomfilter && (
              <Icon name="arrow_outward" size={16} color={secondaryBase} pointer />
            )}
          </Group>
        )}
        <Icon
          name="close"
          onClick={() => {
            setSelectedInitiative(null);
            setActivePanel(null);
          }}
          size={20}
          color={skyDark}
          aria-label="Close panel"
        />
      </Group>
      <Divider />
      <ScrollArea h="100%" scrollbarSize={0}>
        <Stack>
          <Text weight="medium" size="medium">
            {initiative.name}
          </Text>
          <Divider />
          <ProgressBar value={initiative.percent_progress} label="Completion" color={primaryBase} />
          <ProgressBar value={initiative.percent_elapsed} label="Time Elapsed" color={purpleBase} />
          <SideSectionInfo initiative={initiative} />
          <Divider />
          {showChangeLog && <SideSectionChangelog />}
        </Stack>
      </ScrollArea>
      <Button
        variant="secondary"
        size="xs"
        radius="xl"
        fullWidth
        leftSection={<Icon name="remove_red_eye" variant="filled" color={baseWhite} size={14} pointer />}
        onClick={() => {
          navigate(`/application/strategy/${portfolio.id}/${initiative.id}`);
        }}
      >
        <Text size="small" color={baseWhite}>
          More Initiative Details
        </Text>
      </Button>
    </Stack>
  );
};
