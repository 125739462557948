import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { PageLayout } from '../../components/page-layout/page-layout';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useStrategyStore } from '../../store/strategy-store/strategy-store';
import { setActivePanel, setMessage, setSelectedInitiative } from '../../store/strategy-store/strategy-store.actions';
import { MessageStatus, Panel } from '../../store/strategy-store/strategy-store.type';
import { baseWhite } from '../../styles/design-tokens';
import { Button } from '../../ui-library/button/button';
import { Icon } from '../../ui-library/icon/icon';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { useCreateInitiativeMutation } from '../strategy/strategy.hooks';
import { Filters } from './initiative-list/filters/filters';
import { MainSection } from './initiative-list/main-section/main-section';
import { Overview } from './initiative-list/overview/overview';
import { SideSection } from './initiative-list/side-section/side-section';
import { InitiativeModal } from './initiative-modal/initiative-modal';
import { InitiativeModalMode } from './initiative-modal/initiative-modal.types';
import { useStrategyData } from './strategy-v2.hooks';

export const StrategyV2 = () => {
  const { initiativesQuery, kpiOverviewDataQuery } = useStrategyData();

  const initiatives = useStrategyStore((state) => state.initiatives);
  const portfolio = useGlobalStore((state) => state.portfolio);
  const activePanel = useStrategyStore((state) => state.activePanel);
  const message = useStrategyStore((state) => state.message);

  const [opened, { open, close }] = useDisclosure(false);

  // Create ref for clicking outside to dismiss message
  const messageRef = useClickOutside(() => setMessage(null), ['mouseup', 'touchend']);

  const createInitiativeMutation = useCreateInitiativeMutation(portfolio, {
    onSuccess: (data) => {
      initiativesQuery.refetch();
      setMessage({
        status: MessageStatus.Success,
        text: `${data.name} has been created successfully!`,
      });
      close();
    },
    onError: (error) => {
      setMessage({
        status: MessageStatus.Error,
        text: `Failed to create initiative: ${error instanceof Error ? error.message : 'Unknown error occurred'}`,
      });
    },
  });

  return (
    <MenuDrivenContainer
      header="Strategy"
      actions={
        <Button
          leftSection={<Icon name="add_circle" size={16} color={baseWhite} />}
          variant="primary"
          radius="xl"
          onClick={open}
          size="sm"
        >
          Add New
        </Button>
      }
    >
      <BrandedLoadingOverlay
        visible={initiativesQuery.isFetching || kpiOverviewDataQuery.isFetching}
        variant="colored"
        transitionDuration={30}
      />

      {/* Message display area */}
      <div ref={messageRef}>
        {message && (
          <PageHeaderMessage
            message={message.text}
            color={
              message.status === MessageStatus.Success
                ? 'green'
                : message.status === MessageStatus.Error
                  ? 'red'
                  : 'yellow'
            }
            onClose={() => setMessage(null)}
          />
        )}
      </div>

      <PageLayout
        controlBar={null}
        overview={{
          title: 'KPIs Overview',
          content: initiatives.length > 0 ? <Overview /> : null,
          collapsible: initiatives.length > 0,
          isEmpty: initiatives.length === 0,
          emptyLabel: 'No initiatives have been created yet',
        }}
        sideFilters={{
          title: 'Filters',
          content: <Filters />,
          show: activePanel === Panel.Filters,
          setShow: (show) => {
            setActivePanel(show ? Panel.Filters : null);
            if (show) {
              setSelectedInitiative(null);
            }
          },
        }}
        mainSection={<MainSection />}
        sideSection={<SideSection />}
        showSideSection={activePanel === Panel.Side || activePanel === Panel.OtherInitiatives}
      />
      <InitiativeModal
        mode={InitiativeModalMode.Create}
        opened={opened}
        onClose={close}
        onSubmit={createInitiativeMutation.mutateAsync}
        isSubmitting={createInitiativeMutation.isPending}
      />
    </MenuDrivenContainer>
  );
};
