import { Box, Group } from '@mantine/core';
import { exportChart } from '../../../../../helpers/image-downloader/image-downloader';
import { baseWhite } from '../../../../../styles/design-tokens';
import { Button } from '../../../../../ui-library/button/button';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Radio } from '../../../../../ui-library/radio/radio';
import { Text } from '../../../../../ui-library/typography/typography';
import { Metric } from '../../../strategy-v2.types';

type Props = {
  metric: Metric;
  setMetric: (metric: Metric) => void;
  chartRef: React.RefObject<HTMLDivElement>;
};

export const MainSectionControls = ({ metric, setMetric, chartRef }: Props) => {
  const handleExportChart = async () => {
    if (!chartRef.current) return;

    try {
      await exportChart(chartRef.current, 'initiative-throughput-chart.png');
    } catch (error) {
      console.error('Error exporting chart:', error);
    }
  };

  return (
    <Group justify="space-between" w="100%">
      <Box>
        <Text size="large">Throughput</Text>
      </Box>
      <Group gap={16} w="fit-content">
        <Radio label="Tasks" checked={metric === Metric.Tasks} onChange={() => setMetric(Metric.Tasks)} />
        <Radio label="Points" checked={metric === Metric.Points} onChange={() => setMetric(Metric.Points)} />
        <Button
          variant="secondary"
          radius="xl"
          size="xs"
          leftSection={<Icon name="download" color={baseWhite} variant="filled" size={16} />}
          onClick={handleExportChart}
        >
          Export
        </Button>
      </Group>
    </Group>
  );
};
