import { Divider, Flex, Group, ScrollArea, Stack } from '@mantine/core';
import { ChangeEvent } from 'react';
import { Epic } from '../../../api/tasks-client/task-client.type';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { baseWhite, inkLight, secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Checkbox } from '../../../ui-library/checkbox/checkbox';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { InitiativeModalMode } from './initiative-modal.types';

type Props = {
  mode: InitiativeModalMode;
  selectedEpics: Epic[];
  setSelectedEpics: React.Dispatch<React.SetStateAction<Epic[]>>;
  onBack: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
};

export function StepTwo({ mode, selectedEpics, setSelectedEpics, onBack, onCancel, onSubmit, isSubmitting }: Props) {
  const epics = useStrategyStore((state) => state.epics);

  const handleCheckboxChange = (epic: Epic, event: ChangeEvent<HTMLInputElement>) => {
    setSelectedEpics((prev) => (event.target.checked ? [...prev, epic] : prev.filter((e) => e.id !== epic.id)));
  };

  return (
    <Stack gap={24}>
      <Text>Epics contribute to achieving Initiatives. Epics are groups of work consisting of many tasks.</Text>

      <Text size="small" weight="bold">
        Select Epics
      </Text>

      <ScrollArea h={336} scrollbarSize={8} scrollHideDelay={500}>
        <Stack gap={24}>
          {epics.map((epic) => (
            <Stack gap={8} key={epic.id}>
              <Checkbox
                label={
                  <Text size="small" color={secondaryBase} style={{ lineHeight: '20px' }}>
                    {epic.title}
                  </Text>
                }
                checked={selectedEpics.some((e) => e.id === epic.id)}
                onChange={(event) => handleCheckboxChange(epic, event)}
              />
              <Text size="small" style={{ width: 420 }}>
                {epic.name}
              </Text>
            </Stack>
          ))}
        </Stack>
      </ScrollArea>

      <Divider />

      <Flex justify="space-between">
        <Button
          variant="outline"
          radius="xl"
          onClick={onBack}
          leftSection={<Icon name="arrow_back" size={16} color={inkLight} pointer />}
        >
          Back
        </Button>
        <Group gap={16}>
          <Button variant="outline" radius="xl" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            radius="xl"
            onClick={onSubmit}
            rightSection={<Icon name="task_alt" size={16} color={baseWhite} pointer />}
            loading={isSubmitting}
            loaderProps={{ size: 'xs' }}
          >
            {mode === InitiativeModalMode.Edit ? 'Save Changes' : 'Complete and Create'}
          </Button>
        </Group>
      </Flex>
    </Stack>
  );
}
