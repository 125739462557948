import { useState } from 'react';
import { baseWhite, secondaryBase } from '../../../../../styles/design-tokens';

// Custom content component to display the initiative key inside the treemap cells
// Using any for props to match Recharts expectations
export const TreeMapContent = (props: any) => {
  const { x, y, width, height, initiativeKey } = props;
  const [isHovered, setIsHovered] = useState(false);

  // Only render text if the cell is big enough
  const shouldRenderText = width > 30 && height > 20;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          fill: isHovered ? secondaryBase : props.fill,
          stroke: baseWhite,
          strokeWidth: 1,
          cursor: 'pointer',
        }}
      />
      {shouldRenderText && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={15}
          fill={baseWhite}
          style={{
            pointerEvents: 'none',
            fontFamily: 'Figtree',
            fontWeight: '500',
            whiteSpace: 'pre-wrap',
          }}
        >
          {initiativeKey}
        </text>
      )}
    </g>
  );
};
