import { styled } from '@linaria/react';
import { Modal } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import {
  ExtendedInitiative,
  Initiative,
  PreSaveInitiative,
} from '../../../api/initiative-client/initiative-client.type';
import { Epic } from '../../../api/tasks-client/task-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { setSelectedInitiative } from '../../../store/strategy-store/strategy-store.actions';
import { skyBase } from '../../../styles/design-tokens';
import { Text } from '../../../ui-library/typography/typography';
import { useInitialEpics, useInitialValues } from './initiative-modal.hooks';
import { InitiativeModalMode } from './initiative-modal.types';
import { StepOne } from './step-one';
import { StepTwo } from './step-two';

type Props = {
  mode: InitiativeModalMode;
  initiative?: ExtendedInitiative;
  opened: boolean;
  onClose: () => void;
  onSubmit: UseMutateAsyncFunction<PreSaveInitiative, unknown, PreSaveInitiative, unknown>;
  isSubmitting: boolean;
  onDelete?: UseMutateAsyncFunction<void, unknown, void, unknown>;
};

export function InitiativeModal({ mode, initiative, opened, onClose, onSubmit, isSubmitting, onDelete }: Props) {
  const portfolioId = useGlobalStore((state) => state.portfolio?.id);
  const selectedInitiative = useStrategyStore((state) => state.selectedInitiative);

  const initialValues = useInitialValues(initiative);
  const initialEpics = useInitialEpics(initiative);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<Partial<PreSaveInitiative>>({});
  const [selectedEpics, setSelectedEpics] = useState<Epic[]>(initialEpics);

  const form = useForm<Partial<PreSaveInitiative>>({
    initialValues,
    validate: {
      name: isNotEmpty(),
      description: isNotEmpty(),
      start_date: (value, values) => {
        if (value && values.end_date && new Date(values.end_date) < new Date(value)) {
          return 'Start date must be before end date';
        }
      },
      end_date: (value, values) => {
        if (dayjs(value).endOf('day').isBefore(dayjs())) {
          return 'End date must be in the future';
        }
        if (value && values.start_date && new Date(value) < new Date(values.start_date)) {
          return 'End date must be after start date';
        }
      },
    },
  });

  useDeepCompareEffect(() => {
    const currentValues = form.values;
    const hasChanges = !isEqual(currentValues, initialValues);

    if (hasChanges) {
      form.setValues(initialValues);
      form.resetDirty();
    }
  }, [initialValues]);

  const resetModalState = () => {
    setStep(1);
    setFormData({});
    form.reset();
    setSelectedEpics(initialEpics);
  };

  const handleClose = () => {
    const userConfirmed = window.confirm(
      'You may have unsaved changes. Are you sure you want to close without saving?',
    );

    if (userConfirmed) {
      onClose();
      setTimeout(resetModalState, 300);
    }
  };

  const handleStepOneNext = (data: Partial<PreSaveInitiative>) => {
    setFormData({ ...formData, ...data });
    setStep(2);
  };

  const handleStepTwoBack = () => {
    setStep(1);
  };

  const handleStepTwoSubmit = async () => {
    if (!portfolioId) {
      return;
    }

    try {
      await onSubmit({
        ...formData,
        portfolio: portfolioId,
        projects: selectedEpics.flatMap((e) => e.projects.map((p) => p.id)),
        epics: selectedEpics.map((e) => e.id),
      } as PreSaveInitiative);

      resetModalState();

      if (mode === InitiativeModalMode.Edit) {
        setSelectedInitiative({ ...selectedInitiative, ...formData } as Initiative);
      }
    } catch (error) {
      // Error handling is done in the parent component's onError callback
      console.error('Error submitting initiative:', error);
    }
  };

  const title = (
    <Text size="large" weight="bold">
      {mode === 'create' ? 'Create' : 'Edit'} Initiative <span style={{ color: skyBase }}>(Step {step}/2)</span>
    </Text>
  );

  return (
    <StyledModal size={580} opened={opened} onClose={handleClose} title={title} centered>
      {step === 1 && (
        <StepOne mode={mode} form={form} onNext={handleStepOneNext} onCancel={handleClose} onDelete={onDelete} />
      )}
      {step === 2 && (
        <StepTwo
          mode={mode}
          selectedEpics={selectedEpics}
          setSelectedEpics={setSelectedEpics}
          onBack={handleStepTwoBack}
          onCancel={handleClose}
          onSubmit={handleStepTwoSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </StyledModal>
  );
}

export const StyledModal = styled(Modal)`
  .mantine-Modal-content {
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    min-width: 580px;
  }

  .mantine-Modal-header {
    padding: 0;
  }

  .mantine-Modal-close {
    position: absolute;
    right: 0px;
    top: 0px;
    color: var(--sky-dark);
    background-color: transparent;
  }

  .mantine-Modal-body {
    padding: 0;
  }
`;
