import { Checkbox, ScrollArea, Stack } from '@mantine/core';
import { ChangeEvent, useState } from 'react';
import { Epic } from '../../../../../api/tasks-client/task-client.type';
import { useStrategyStore } from '../../../../../store/strategy-store/strategy-store';
import { secondaryBase } from '../../../../../styles/design-tokens';
import { Text } from '../../../../../ui-library/typography/typography';

export const FiltersEpics = () => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  const [selectedEpics, setSelectedEpics] = useState<Epic[]>([]);

  const handleCheckboxChange = (epic: Epic, event: ChangeEvent<HTMLInputElement>) => {
    setSelectedEpics((prev) => (event.target.checked ? [...prev, epic] : prev.filter((e) => e.id !== epic.id)));
  };

  if (!initiative) {
    return null;
  }

  return (
    <ScrollArea h="100%" scrollbarSize={0}>
      <Stack gap={24}>
        {initiative.epics.map((epic) => (
          <Stack gap={8} key={epic.id}>
            <Checkbox
              label={
                <Text size="small" color={secondaryBase} style={{ lineHeight: '20px' }}>
                  {epic.title}
                </Text>
              }
              checked={selectedEpics.some((e) => e.id === epic.id)}
              onChange={(event) => handleCheckboxChange(epic, event)}
            />
            <Text size="small" style={{ width: 420 }}>
              {epic.name}
            </Text>
          </Stack>
        ))}
      </Stack>
    </ScrollArea>
  );
};
