import { Group, ScrollArea, Stack, Textarea, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { PreSaveInitiative, RelativeBusinessValueUnits } from '../../../api/initiative-client/initiative-client.type';
import { baseWhite, inkLight, redBase, skyDark } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Radio } from '../../../ui-library/radio/radio';
import { Text } from '../../../ui-library/typography/typography';
import { commonInputProps } from './initiative-modal.helpers';
import classes from './initiative-modal.module.css';
import { InitiativeModalMode } from './initiative-modal.types';
type Props = {
  mode: InitiativeModalMode;
  form: UseFormReturnType<Partial<PreSaveInitiative>>;
  onNext: (data: Partial<PreSaveInitiative>) => void;
  onCancel: () => void;
  onDelete?: () => void;
};

export function StepOne({ mode, form, onNext, onCancel, onDelete }: Props) {
  return (
    <form onSubmit={form.onSubmit((values) => onNext(values))}>
      <ScrollArea h={480}>
        <Stack gap={24}>
          <Text>Initiatives represent broad strategic goals and objectives for your organization.</Text>

          <TextInput
            required
            label="Name"
            placeholder="Enter initiative name here"
            maxLength={150}
            {...form.getInputProps('name')}
            {...commonInputProps}
          />

          <Divider />

          <Textarea
            required
            label="Description"
            placeholder="Enter initiative description here"
            maxLength={1000}
            maxRows={8}
            {...form.getInputProps('description')}
            {...commonInputProps}
            autosize
            classNames={classes}
          />

          <Divider />

          <Group gap={24}>
            <DatePickerInput
              label="Start Date"
              placeholder="Pick date"
              {...form.getInputProps('start_date')}
              popoverProps={{ zIndex: 600 }}
              {...commonInputProps}
              flex={1}
              rightSection={<Icon name="calendar_month" size={16} color={skyDark} />}
              rightSectionPointerEvents="none"
              h={80}
            />
            <DatePickerInput
              label="End Date"
              placeholder="Pick date"
              {...form.getInputProps('end_date')}
              popoverProps={{ zIndex: 600 }}
              {...commonInputProps}
              flex={1}
              rightSection={<Icon name="calendar_month" size={16} color={skyDark} />}
              rightSectionPointerEvents="none"
              h={80}
            />
          </Group>

          <Group gap={24} h={96}>
            <TextInput
              label="Relative Business Value"
              placeholder="Enter relative business value here"
              {...form.getInputProps('relative_business_value')}
              {...commonInputProps}
              flex={1}
              type="number"
              min={0}
              max={999999999}
              style={{
                alignSelf: 'flex-start',
              }}
            />
            <Group gap={16} flex={1}>
              <Radio
                label="Numeric"
                checked={form.values.relative_business_value_unit === RelativeBusinessValueUnits.numeric}
                onChange={() => form.setFieldValue('relative_business_value_unit', RelativeBusinessValueUnits.numeric)}
              />
              <Radio
                label="Dollar"
                checked={form.values.relative_business_value_unit === RelativeBusinessValueUnits.dollars}
                onChange={() => form.setFieldValue('relative_business_value_unit', RelativeBusinessValueUnits.dollars)}
              />
            </Group>
          </Group>
        </Stack>
      </ScrollArea>

      <Stack gap={24}>
        <Divider />

        <Group justify="space-between">
          {mode === InitiativeModalMode.Edit && (
            <Button
              radius="xl"
              variant="outline"
              onClick={() => onDelete?.()}
              leftSection={<Icon name="delete" color={inkLight} size={18} pointer />}
              style={{ borderColor: redBase }}
            >
              Delete Initiative
            </Button>
          )}
          <Group justify="flex-end" gap={16} flex={1}>
            <Button radius="xl" variant="outline" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              type="submit"
              radius="xl"
              variant="secondary"
              rightSection={<Icon name="arrow_forward" color={baseWhite} size={16} pointer />}
            >
              Next Step
            </Button>
          </Group>
        </Group>
      </Stack>
    </form>
  );
}
