import { Stack } from '@mantine/core';
import { Initiative, InitiativeStatus } from '../../../../api/initiative-client/initiative-client.type';
import { primaryBase, skyLight } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { Label } from '../filters/status-label';
import { getSourceIcon, getStatusColor } from './main-section.helpers';

export const InitiativeIDCell = ({ initiative }: { initiative: Initiative }) => {
  if (!initiative.key) {
    return null;
  }

  return (
    <Label color={getStatusColor(initiative.status?.by_tasks?.status || InitiativeStatus.Unknown)}>
      <Text size="small" lineHeight="none">
        {initiative.key}
      </Text>
      <img src={getSourceIcon(initiative.source)} width={16} height={16} alt={initiative.source} />
    </Label>
  );
};

export const CompletionCell = ({ initiative }: { initiative: Initiative }) => {
  return (
    <Stack gap={1} mb={4}>
      <Text size="small">{initiative.percent_progress}%</Text>
      <div
        style={{
          width: '100%',
          height: '4px',
          backgroundColor: skyLight,
          borderRadius: '2px',
        }}
      >
        <div
          style={{
            width: `${initiative.percent_progress}%`,
            height: '100%',
            backgroundColor: primaryBase,
            borderRadius: '2px',
            transition: 'width 0.3s ease',
          }}
        />
      </div>
    </Stack>
  );
};
