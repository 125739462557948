import { Flex, Group } from '@mantine/core';
import { InitiativeSource } from '../../../api/initiative-client/initiative-client.type';
import { baseWhite, secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Tooltip } from '../../../ui-library/tooltip/tooltip';
import { H3 } from '../../../ui-library/typography/typography';
import { useInitiativePerformance } from './initiative-performance.hooks';

type Props = {
  openInfoModal: (isOpen: boolean) => void;
  openEditModal: (isOpen: boolean) => void;
};

export const InitiativePerformanceControlBar = ({ openInfoModal, openEditModal }: Props) => {
  const initiative = useInitiativePerformance();

  if (!initiative) {
    return null;
  }

  const isInternalInitiative = initiative.source === InitiativeSource.Bloomfilter;

  return (
    <Group justify="space-between" align="center">
      <Flex gap={8} align="center">
        <Icon name="info" size={24} color={secondaryBase} pointer onClick={() => openInfoModal(true)} />
        <Tooltip
          variant="white"
          position="bottom"
          label={initiative.name}
          disabled={!initiative.name || (!!initiative.name && initiative.name.length <= 80)}
          maw={600}
          multiline
        >
          <H3
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'default',
            }}
          >
            {initiative.name && initiative.name.length > 80
              ? `${initiative.name.substring(0, 80)}...`
              : initiative.name || ''}
          </H3>
        </Tooltip>
      </Flex>
      {isInternalInitiative && (
        <Button
          variant="secondary"
          size="sm"
          radius="xl"
          leftSection={<Icon variant="filled" name="mode" size={16} color={baseWhite} pointer />}
          onClick={() => openEditModal(true)}
        >
          Edit Initiative
        </Button>
      )}
    </Group>
  );
};
