import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import {
  setSourceFilters,
  setStateFilters,
  setStatusFilters,
} from '../../../../store/strategy-store/strategy-store.actions';
import { getSourceOptions, getStateOptions, getStatusOptions } from './filters.helpers';
import { FilterGroup } from './filters.type';

/**
 * Custom hook that returns an array of filter groups for the strategy view
 * Each filter group contains configuration for either status or source filtering
 *
 * The status filter group allows filtering by item status (In Progress, Not Active, etc)
 * The source filter group allows filtering by data source (Bloomfilter, Jira, etc)
 *
 * Both groups support:
 * - Multiple selection
 * - Show all option
 * - Material icon
 * - Custom labels
 *
 * @returns {FilterGroup[]} Array of filter group configurations with current selected values and change handlers
 */
const useFilterGroups = (): FilterGroup[] => {
  const statusFilters = useStrategyStore((state) => state.filters.status);
  const sourceFilters = useStrategyStore((state) => state.filters.source);
  const stateFilters = useStrategyStore((state) => state.filters.state);

  return [
    {
      id: 'status',
      name: 'Status',
      icon: 'filter_tilt_shift',
      options: getStatusOptions(),
      selectedValues: statusFilters,
      onSelectionChange: setStatusFilters,
      showAllLabel: 'All Statuses',
    },
    {
      id: 'state',
      name: 'State',
      icon: 'flare',
      options: getStateOptions(),
      selectedValues: stateFilters,
      onSelectionChange: setStateFilters,
      showAllLabel: 'All States',
    },
    {
      id: 'source',
      name: 'Source',
      icon: 'cloud_done',
      options: getSourceOptions(),
      selectedValues: sourceFilters,
      onSelectionChange: setSourceFilters,
      showAllLabel: 'All Sources',
    },
  ];
};

export { useFilterGroups };
