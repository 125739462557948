import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { MainSection as MainSectionCost } from './cost-tab/main-section/main-section';
import { MainSection as MainSectionFlowByPhase } from './flow-by-phase-tab/main-section/main-section';
import { MainSection as MainSectionFocus } from './focus-tab/main-section/main-section';
import { PerformanceTab } from './initiative-performance.types';
import { MainSection as MainSectionRelatedTasks } from './related-tasks-tab/main-section/main-section';
import { MainSection as MainSectionTargets } from './targets-tab/main-section/main-section';
import { MainSection as MainSectionThroughput } from './throughput-tab/main-section/main-section';

export const InitiativePerformanceMainSection = () => {
  const performanceTab = useStrategyStore((state) => state.performanceTab);

  switch (performanceTab) {
    case PerformanceTab.Throughput:
      return <MainSectionThroughput />;
    case PerformanceTab.Focus:
      return <MainSectionFocus />;
    case PerformanceTab.FlowByPhase:
      return <MainSectionFlowByPhase />;
    case PerformanceTab.Targets:
      return <MainSectionTargets />;
    case PerformanceTab.Cost:
      return <MainSectionCost />;
    case PerformanceTab.RelatedTasks:
      return <MainSectionRelatedTasks />;
    default:
      return null;
  }
};
