import { TooltipProps } from 'recharts';
import { baseWhite } from '../../../../../../styles/design-tokens';
import { barColors } from './bar-chart.mocks';

export const BarChartTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: baseWhite,
          padding: '8px 12px',
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
      >
        <p style={{ margin: '0 0 5px', fontWeight: 'bold' }}>{label}</p>
        {payload.map((entry: any, index: number) => {
          const dataKey = entry.name;
          return (
            <p key={`item-${index}`} style={{ margin: '0', color: barColors[dataKey as keyof typeof barColors] }}>
              {entry.name}: {entry.value}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};
