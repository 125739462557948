import { styled } from '@linaria/react';
import { Checkbox as MantineCheckbox, CheckboxProps as MantineCheckboxProps } from '@mantine/core';

interface Props extends MantineCheckboxProps {
  label?: string | React.ReactNode;
}

export const Checkbox = ({ label, ...props }: Props) => {
  return <StyledCheckbox label={label} {...props} />;
};

const StyledCheckbox = styled(MantineCheckbox)`
  .mantine-Checkbox-input {
    cursor: pointer;
    border-radius: 4px;
    
    &:checked {
      background-color: var(--secondary-base);
      border-color: var(--secondary-base);
      
      & + .mantine-Checkbox-icon {
        color: var(--base-white);
      }
    }
    
    &:hover:not(:checked) {
      border-color: var(--secondary-light);
    }
  }
  
  .mantine-Checkbox-label {
    cursor: pointer;
  }
  
  &[data-disabled] {
    .mantine-Checkbox-input, .mantine-Checkbox-label {
      cursor: not-allowed;
    }
    
    .mantine-Checkbox-input {
      background-color: var(--sky-lighter);
      border-color: var(--sky-base);
    }
  }
`;
