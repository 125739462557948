import { Box, Flex, Progress as MantineProgress } from '@mantine/core';
import { ReactNode } from 'react';
import { primaryLight } from '../../styles/design-tokens';
import { Text } from '../../ui-library/typography/typography';
import { OverviewCard } from './overview-card';

type Props = {
  title: string;
  icon?: string;
  status?: string | ReactNode;
  color?: string;
  value: number;
};

export const ProgressBarCard = ({ title, icon, status, value, color = primaryLight }: Props) => {
  const content = (
    <Flex direction="column" gap={8} h="100%">
      <Box style={{ flexGrow: 1 }}>{status}</Box>

      <Flex align="center" gap={24} style={{ marginTop: 'auto' }}>
        <MantineProgress value={value} size={16} radius={4} color={color} style={{ flex: 1 }} />
        <Text size="large" weight="bold">
          {value}%
        </Text>
      </Flex>
    </Flex>
  );

  return <OverviewCard title={title} icon={icon} content={content} />;
};
