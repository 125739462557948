import { ScrollArea, Stack } from '@mantine/core';
import { FilterPanelGroup } from '../../../../components/filters/filter-panel-group';
import { FilterPanelHeader } from '../../../../components/filters/filter-panel-header';
import { useStrategyStore } from '../../../../store/strategy-store/strategy-store';
import { resetFilters, toggleCollapsedGroup } from '../../../../store/strategy-store/strategy-store.actions';
import { Divider } from '../../../../ui-library/divider/divider';
import { Text } from '../../../../ui-library/typography/typography';
import { useFilterGroups } from './filters.hooks';
import { FilterGroup } from './filters.type';

export function Filters() {
  const filterGroups = useFilterGroups();
  const collapsedGroups = useStrategyStore((state) => state.collapsedGroups);

  const isGroupCollapsed = (group: FilterGroup): boolean => {
    return collapsedGroups[group.id];
  };

  const isAllSelected = (group: FilterGroup): boolean => {
    return group.selectedValues.length === group.options.length;
  };

  const handleAllChange = (group: FilterGroup, isChecked: boolean): void => {
    group.onSelectionChange(isChecked ? group.options.map((opt) => opt.value) : []);
  };

  const handleIndividualChange = (group: FilterGroup, value: string, isChecked: boolean): void => {
    const newValues = isChecked ? [...group.selectedValues, value] : group.selectedValues.filter((v) => v !== value);

    group.onSelectionChange(newValues);
  };

  return (
    <Stack gap="md" h="100%">
      <Text size="medium" weight="medium">
        Initiatives
      </Text>
      <Divider />
      <FilterPanelHeader onResetFilters={resetFilters} />
      <Divider />

      <ScrollArea h="100%" style={{ flex: 1 }}>
        <Stack gap="md">
          {filterGroups.map((group) => (
            <FilterPanelGroup
              key={group.id}
              group={group}
              toggleCollapsed={(group) => toggleCollapsedGroup(group.id)}
              isCollapsed={isGroupCollapsed(group)}
              isAllSelected={isAllSelected(group)}
              handleAllChange={handleAllChange}
              handleIndividualChange={handleIndividualChange}
              hasDivider={group !== filterGroups[filterGroups.length - 1]}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
}
