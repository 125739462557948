import { Flex, Stack } from '@mantine/core';
import { OverviewCard } from '../../../components/overview-card/overview-card';
import { ProgressBarCard } from '../../../components/overview-card/progress-bar-card';
import { useStrategyStore } from '../../../store/strategy-store/strategy-store';
import { graphCambridgeBlue, purpleBase, skyDark } from '../../../styles/design-tokens';
import { H3, Text } from '../../../ui-library/typography/typography';
import { formatInitiativeDate } from '../strategy-v2.helpers';
import { getCompletedTasksCount } from './initiative-performance.helpers';

export const InitiativePerformanceOverview = () => {
  const initiativePerformance = useStrategyStore((state) => state.initiativePerformance);

  return (
    <>
      <Flex direction="row" gap={24}>
        <ProgressBarCard
          title="Initiative Completion"
          icon="check_circle"
          status={
            <Flex direction="column">
              <Flex justify="space-between">
                <Text size="tiny" color={skyDark}>
                  Start Date
                </Text>
                <Text size="tiny">{formatInitiativeDate(initiativePerformance?.start_date)}</Text>
              </Flex>
              <Flex justify="space-between">
                <Text size="tiny" color={skyDark}>
                  End Date
                </Text>
                <Text size="tiny">{formatInitiativeDate(initiativePerformance?.end_date)}</Text>
              </Flex>
            </Flex>
          }
          value={initiativePerformance?.percent_progress || 0}
          color={graphCambridgeBlue}
        />
        <ProgressBarCard
          title="Time Elapsed"
          icon="check_circle"
          value={initiativePerformance?.percent_elapsed || 0}
          color={purpleBase}
        />
        <OverviewCard
          title="Cost to Date"
          icon="payments"
          content={
            <Flex align="center" justify="center" h="100%">
              <H3>${initiativePerformance?.costs_to_date?.initiative || 0}</H3>
            </Flex>
          }
        />
        <OverviewCard
          title="Throughput"
          icon="show_chart"
          content={
            <Stack justify="center" align="center" gap={0} mt={10}>
              <H3>{getCompletedTasksCount(initiativePerformance)}</H3>
              <Text size="tiny" lineHeight="tight" color={skyDark}>
                Tasks Completed
              </Text>
              <Text size="tiny" lineHeight="tight" color={skyDark}>
                Last 30 Days
              </Text>
            </Stack>
          }
        />
      </Flex>
    </>
  );
};
